import React, { useState, useEffect, Fragment } from "react";
import { useParams } from 'react-router-dom';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField, MenuItem, Select, FormControl, Chip } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import Files from 'react-files';

const EditFarm = () => {
    const { farmid } = useParams();
    const [isloading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [country, setcountry] = useState([])
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [langauges, setLangauges] = useState([])
    const [unrelatedBreeds, setUnrelatedBreeds] = useState([]);
    const [selectedBreeds, setSelectedBreeds] = useState([]);
    const [user, setuser] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        capacity: "",
        number_of_chickens: "",
        types_of_breeds: "",
        health_monitoring_system_status: "",
        gps_rfid_tracking_system_status: "",
        latitude: "",
        longitude: "",
        country_id: "",
        city: "",
        street: "",
        building: "",
        area: "",
        zipcode: "",
        user_id: [],
    })

    useEffect(() => {
        getUser();
        get_farm()
        get_country()
        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);

            setDataToEditorState(prev => {
                array.forEach((e) => {
                    prev[e.id] = {
                        name: { id: 0, value: "" },
                        // advantages: { id: 0, value: ""}, 
                        description: { id: 0, value: "" },
                        // brief: { id: 0, value: ""}, 
                    };
                });
                return prev;
            });

        }

    }, [])
    const get_country = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.country.view,
            hasToken: true,
        })
        if (response) {
            setcountry([])
            response.data.forEach(ele => {
                setcountry(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }])
            })
        } else {
            console.log(message);
        }
    }
    const get_farm = async () => {

        const { response, message } = await Helper.Get({
            url: api_Routes.farm.getOne(farmid),
            hasToken: true,
            data: { detailed: 1 }

        })
        if (response) {
            var details_languages = {}
            setUnrelatedBreeds(response.data.unrelated_breeds);
            setSelectedBreeds(response.data.breeds.map(breed => breed.id));



            console.log(response.data)
            setFormData({
                name: response.data.name,
                capacity: response.data.capacity,
                number_of_chickens: response.data.number_of_chickens,
                // types_of_breeds:response.data.types_of_breeds,              
                // gps_rfid_tracking_system_status:response.data.gps_rfid_tracking_system_status,              
                latitude: response.data.latitude,
                longitude: response.data.longitude,
                city: response.data.location.city,
                area: response.data.location.area,
                street: response.data.location.street,
                country_id: response.data.location.country_id,
                building: response.data.location.building,
                zipcode: response.data.location.zipcode,
                // health_monitoring_system_status:response.data.health_monitoring_system_status,   
                photo: response.data.photos,
                user_id: response.data.user_id,
            })
            response.data.detailed_translations.forEach(ele => {
                ele.forEach(dt => {
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}


                    details_languages[dt.language_id][dt.text_type] = { value: dt.value, id: dt.id }
                })
            })
            setFiles(response.data.photos)

            setDataToEditorState(details_languages)


        }

    }
    const getUser = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.user.view,
            hasToken: true,
            data: {
                results: 190,
            }
        });
        if (response) {
            setuser([]);
            response.data.forEach(ele => {
                setuser(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }]);
            });
        } else {
            console.log(message);
        }
    }
    const handleChange = (key, value, id_lang, id, sort) => {

        if (id_lang) {
            let translations = { ...dataToEditorState };
            let valueTemp = value;
            translations[id_lang][key] = { id: id, value: valueTemp };
            setDataToEditorState(translations);
        } else {
            setFormData(prev => ({ ...prev, [key]: value }));
        }
    };
    const handleBreedChange = (event) => {
        const { value } = event.target;
        setSelectedBreeds(value);
    };

    const handleSubmit = async () => {
        let exit = false;

        const requiredFields = [
            "name",
            "capacity",
            "number_of_chickens",
        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0 || !formData.photo) {
            let errorMessage = "";

            if (missingFields.length > 0) {
                errorMessage += `Please fill in all required fields: ${missingFields.join(", ")}. `;
            }

            if (!formData.photo) {
                errorMessage += `Please upload a photo.`;
            }

            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            setIsLoading(false);
            return;
        }

        if (exit) {
            enqueueSnackbar("Please add name, advantages, description, brief", {
                variant: "error",
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const form_data = new FormData();

        // Append form data
        Object.keys(formData).forEach((key) => {
            if (key === "photo") {
                formData[key].forEach((file) => {
                    if (file instanceof File || file instanceof Blob) {
                        form_data.append('photos[]', file);
                    }
                });
            } else {
                form_data.append(key, formData[key]);
            }
        });

        // Append breeds to form data
        selectedBreeds.forEach((breedId) => {
            form_data.append('breed_ids[]', breedId);
        });


        for (const pair of form_data.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        const { response, message } = await Helper.Post({
            url: api_Routes.farm.update(farmid),
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            });
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            });
            setIsLoading(false);
        }
    };


    const [files, setFiles] = useState([]);

    function deleteFile(indexToRemove) {
        const newFiles = files.filter((_, index) => index !== indexToRemove);
        setFiles(newFiles);
        setFormData(prev => ({
            ...prev,
            photo: newFiles.map(f => f.file) // Update formData with remaining files
        }));
    }
    const onFilesChange = (uploadedFiles) => {
        const newFiles = uploadedFiles.map(file => ({
            url: URL.createObjectURL(file),
            file // Store the actual file object for uploading later
        }));

        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        setFormData(prev => ({ ...prev, photo: [...prev.photo, ...newFiles.map(f => f.file)] }));
    };
    const onFilesError = (error, file) => {
        setFormData(prev => ({ ...prev, ["photo"]: "" }))
        setFiles(file)
    }

    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Edit Farm</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isloading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3 style={{ fontWeight: 500, marginBottom: "30px" }}>Basic Information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Farm Name</InputLabel>
                                    <TextField

                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => { handleChange("name", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Capacity</InputLabel>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="capacity"
                                        value={formData.capacity}
                                        onChange={(e) => { handleChange("capacity", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Number of Chickens</InputLabel>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="number_of_chickens"
                                        value={formData.number_of_chickens}
                                        onChange={(e) => { handleChange("number_of_chickens", e.target.value) }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Types of Breeds</InputLabel>
                                    <TextField 
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="types_of_breeds" 
                                        value={formData.types_of_breeds} 
                                        onChange={(e)=>{handleChange("types_of_breeds",e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Health Monitoring System Status</InputLabel>
                                    <TextField 
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="health_monitoring_system_status" 
                                        value={formData.health_monitoring_system_status} 
                                        onChange={(e)=>{handleChange("health_monitoring_system_status",e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">GPS RFID Tracking System Status</InputLabel>
                                    <TextField 
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="gps_rfid_tracking_system_status" 
                                        value={formData.gps_rfid_tracking_system_status} 
                                        onChange={(e)=>{handleChange("gps_rfid_tracking_system_status",e.target.value)}}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={4}>
                                    <Typography mb="10px">Breeds</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>Breeds</InputLabel>
                                        <Select
                                            multiple
                                            value={selectedBreeds}
                                            onChange={handleBreedChange}
                                            renderValue={(selected) => selected
                                                .map((value) => unrelatedBreeds.find(breed => breed.id === value)?.name)
                                                .join(', ')
                                            }
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" } }}
                                        >
                                            {unrelatedBreeds.map((breed) => (
                                                <MenuItem key={breed.id} value={breed.id}>
                                                    {breed.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>



                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Latitude</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="location"
                                        value={formData.latitude}
                                        onChange={(e) => { handleChange("latitude", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Longitude</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="location"
                                        value={formData.longitude}
                                        onChange={(e) => { handleChange("longitude", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography mb="10px">user</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>users</InputLabel>
                                        <Select
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}

                                            labelId="role-select-label"
                                            value={formData.user_id}
                                            label="breed"
                                            onChange={(e) => { handleChange("user_id", e.target.value) }}
                                        >
                                            {user.map((user) => (
                                                <MenuItem key={user.value} value={user.value}>{user.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={{ marginTop: "25px" }}>
                    <CardContent>
                        <h3 style={{ fontWeight: 500, marginBottom: "30px" }}>Address Information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>

                                    <Typography mb="10px">Country</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>country</InputLabel>
                                        <Select
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}
                                            labelId="role-select-label"
                                            value={formData.country_id}
                                            label="country"
                                            onChange={(e) => { handleChange("country_id", e.target.value) }}
                                        >
                                            {country.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">City</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="city"
                                        variant="outlined"
                                        name="city"
                                        value={formData.city}
                                        onChange={(e) => { handleChange("city", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Street</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="street"
                                        variant="outlined"
                                        name="street"
                                        value={formData.street}
                                        onChange={(e) => { handleChange("street", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Building</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="building"
                                        variant="outlined"
                                        name="building"
                                        value={formData.building}
                                        onChange={(e) => { handleChange("building", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Zipcode</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="zipcode"
                                        variant="outlined"
                                        name="zipcode"
                                        value={formData.zipcode}
                                        onChange={(e) => { handleChange("zipcode", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Area</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="area"
                                        variant="outlined"

                                        name="area"
                                        value={formData.area}
                                        onChange={(e) => { handleChange("area", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (<Container sx={{ marginBottom: "20px" }}>
                    <Card>
                        <CardContent>
                            <h3>{`${lang.name} information`}</h3>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Name</InputLabel>
                                        <TextField
                                            label="name"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.name.value}
                                            onChange={(e) => { handleChange("name", e.target.value, lang.id, dataToEditorState[JSON.stringify(lang.id)]?.name.id, 0) }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Advantages</InputLabel>
                                    <TextField 
                                        label="advantages" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="origin" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.advantages.value} 
                                        onChange={(e)=>{handleChange("advantages",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.advantages.id,1)}} 
                                    />
                                </Grid> */}
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Description</InputLabel>
                                        <TextField
                                            label="description"
                                            variant="outlined"
                                            fullWidth
                                            name="type"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.description.value}
                                            onChange={(e) => { handleChange("description", e.target.value, lang.id, dataToEditorState[JSON.stringify(lang.id)]?.description.id, 2) }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Brief</InputLabel>
                                    <TextField 
                                        label="brief" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="type" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.brief.value} 
                                        onChange={(e)=>{handleChange("brief",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.brief.id,3)}}  
                                    />
                                </Grid> */}
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>))}

            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} textAlign={"center"}>
                                    <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={true}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        <Grid container spacing={2}>
                                            {files.length > 0 && (
                                                <>
                                                    {files.map((file, index) => (
                                                        <Grid item xs={12} sm={6} key={index}>
                                                            <img
                                                                width="100%" // Adjust width to fit within the grid item
                                                                height="200px"
                                                                alt="img"
                                                                src={file.url}  // Display using the URL
                                                                style={{ objectFit: "cover", borderRadius: "8px" }}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </>
                                            )}
                                        </Grid>
                                        <Button
                                            sx={{
                                                marginTop: "20px",
                                                backgroundColor: "#244729",
                                                color: "white",
                                                padding: "10px",
                                                '&:hover': { backgroundColor: "#244710", color: "white" }
                                            }}
                                        >
                                            Upload Image
                                        </Button>
                                    </Files>
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", flexDirection: "row" }}>
                                        {files.length > 0 && files.map((file, index) => (
                                            <Button
                                                key={index}
                                                onClick={() => deleteFile(index)}
                                                sx={{
                                                    backgroundColor: "red",
                                                    marginLeft: "8px",
                                                    color: "white",
                                                    padding: "8px 16px",
                                                    '&:hover': { backgroundColor: "darkred", color: "white" }
                                                }}
                                            >
                                                Delete {`${index + 1}`}
                                            </Button>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    )

}
export default EditFarm