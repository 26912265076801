import { Update } from "@mui/icons-material"

// const Host = "https://api.rentchicken.net/api/"
const Host = "https://staging-api.rentchicken.net/api"

export const api_Routes = {
    auth: {
        login: `${Host}/auth/dashboard`,
    },
    language: {
        view: `${Host}/languages`,
    },
    chicken: {
        view: `${Host}/chickens`,
        add: `${Host}/chickens`,
        bulkDelete: (id) => (`${Host}/chickens/${id}`),
        getOne: (id) => (`${Host}/chickens/${id}`),
        update: (id) => (`${Host}/chickens/${id}`),
    },
    egg: {
        view: `${Host}/eggs`,
        add: `${Host}/eggs`,
        bulkDelete: (id) => (`${Host}/eggs/${id}`),
        getOne: (id) => (`${Host}/eggs/${id}`),
        update: (id) => (`${Host}/eggs/${id}`),
    },
    breed: {
        view: `${Host}/breeds`,
        add: `${Host}/breeds`,
        bulkDelete: (id) => (`${Host}/breeds/${id}`),
        getOne: (id) => (`${Host}/breeds/${id}`),
        update: (id) => (`${Host}/breeds/${id}`),
    },
    Shipping: {
        add: `${Host}/shippingOptions`,
        view: `${Host}/shippingOptions`,
        bulkDelete: (id) => (`${Host}/shippingOptions/${id}`),
        getOne: (id) => (`${Host}/shippingOptions/${id}`),
        update: (id) => (`${Host}/shippingOptions/${id}`),
    },
    Company: {
        add: `${Host}/shippingCompany`,
        view: `${Host}/shippingCompany`,
        bulkDelete: (id) => (`${Host}/shippingCompany/${id}`),
        getOne: (id) => (`${Host}/shippingCompany/${id}`),
        update: (id) => (`${Host}/shippingCompany/${id}`),


    },
    farm: {
        add: `${Host}/farms/addDashFarm`,
        view: `${Host}/farms`,
        bulkDelete: (id) => (`${Host}/farms/${id}`),
        getOne: (id) => (`${Host}/farms/${id}`),
        update: (id) => (`${Host}/farms/${id}`),
    },
    role: {
        view: `${Host}/roles`,
        add: `${Host}/roles`,
        bulkDelete: (id) => (`${Host}/roles/${id}`),
        getOne: (id) => (`${Host}/roles/${id}`),
        update: (id) => (`${Host}/roles/${id}`),
    },
    permission: {
        view: `${Host}/permissions`,
        add: `${Host}/permissions/assign`,
        bulkDelete: (id) => (`${Host}/permissions/${id}`),
        getOne: (id) => (`${Host}/permissions/${id}`),
        update: (id) => (`${Host}/permissions/${id}`),
    },
    user: {
        view: `${Host}/users`,
        add: `${Host}/users`,
        bulkDelete: (id) => (`${Host}/users/${id}`),
        getOne: (id) => (`${Host}/users/${id}`),
        update: (id) => (`${Host}/users/${id}`),
        verifed: (id) => (`${Host}/auth/secure/${id}`),
    },
    country: {
        view: `${Host}/countries`,
        add: `${Host}/countries`,
        bulkDelete: (id) => (`${Host}/countries/${id}`),
        getOne: (id) => (`${Host}/countries/${id}`),
        update: (id) => (`${Host}/countries/${id}`),
    },
    useraddress: {
        view: `${Host}/userAddresses`,
        add: `${Host}/userAddresses`,
        bulkDelete: (id) => (`${Host}/userAddresses/${id}`),
        getOne: (id) => (`${Host}/userAddresses/${id}`),
        update: (id) => (`${Host}/userAddresses/${id}`),
    },
    reservation: {
        view: `${Host}/reservations`,
        add: `${Host}/reservations`,
        bulkDelete: (id) => (`${Host}/reservations/${id}`),
        getOne: (id) => (`${Host}/reservations/${id}`),
        update: (id) => (`${Host}/reservations/${id}`),
    },
    page: {
        view: `${Host}/pages`,
        add: `${Host}/pages`,
        bulkDelete: (id) => (`${Host}/pages/${id}`),
        getOne: (id) => (`${Host}/pages/${id}`),
        update: (id) => (`${Host}/pages/${id}`),
    },
    section: {
        view: `${Host}/pagesSections`,
        add: `${Host}/pagesSections`,
        bulkDelete: (id) => (`${Host}/pagesSections/${id}`),
        getOne: (id) => (`${Host}/pagesSections/${id}`),
        update: (id) => (`${Host}/pagesSections/${id}`),
    },
    notification: {
        view: `${Host}/notificationTexts`,
        add: `${Host}/notificationTexts`,
        bulkDelete: (id) => (`${Host}/notificationTexts/${id}`),
        getOne: (id) => (`${Host}/notificationTexts/${id}`),
        update: (id) => (`${Host}/notificationTexts/${id}`),
    },
    store: {
        view: `${Host}/stores`,
        bulkDelete: (id) => (`${Host}/stores/${id}`),
    },
    order: {
        view: `${Host}/orders`,
        getOne: (id) => (`${Host}/orders/${id}`),
    },
    chart: {
        order: `${Host}/reports/orderStatus`,
        user: `${Host}/reports/userChartsByYear`,
    },
    package: {
        view: `${Host}/packages`,
        add: `${Host}/packages`,
        bulkDelete: (id) => (`${Host}/packages/${id}`),
        getOne: (id) => (`${Host}/packages/${id}`),
        update: (id) => (`${Host}/packages/${id}`),
    },
    balance: {
        getOne: (id) => (`${Host}/balances?user_id=${id}`),
        view: `${Host}balances/InbyUser`,
        add: `${Host}/balances/addadmin`,
    },
    Settings: {
        view: `${Host}/settings/list`,
        update: `${Host}/settings`,

    }

}
