import React , {useState , useEffect , Fragment} from "react";
import DataTable from 'react-data-table-component';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import {  Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';


const AddAddress = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [user, setuser] = useState([])
    const [country, setcountry] = useState([])
    const [formData, setFormData]  = useState({
        user_id:"",
        country_id:"",
        city:"",
        street:"",
        building:"",
        area:"",
        zipcode:"",
    })
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {
        
    get_user()
    get_country()

    
    }, []);

    const get_user = async()=>{
        const {response, message} = await Helper.Get({
            url:api_Routes.user.view,
            hasToken:true,
        })
        if(response){
            setuser([])
            response.data.forEach(ele=>{
            setuser(prev => [...prev, {
                label:ele.name, 
                value: ele.id   
            }])
            })
        }else{
            console.log(message);
        }
        }

    const get_country = async()=>{
        const {response, message} = await Helper.Get({
            url:api_Routes.country.view,
            hasToken:true,
        })
        if(response){
            setcountry([])
            response.data.forEach(ele=>{
            setcountry(prev => [...prev, {
                label:ele.name, 
                value: ele.id   
            }])
            })
        }else{
            console.log(message);
        }
        }


    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

     const handleSubmit = async () => {
            let exit = false
   const requiredFields = [
      "user_id",
     "country_id",
        "city",
        "street",
        "building",
        "area",
        "zipcode",
    ];

    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Please fill in all required fields: ${missingFields.join(", ")}`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

              
                form_data.append("_method", "PUT");
            
           

            const { response, message } = await Helper.Post({
                url: api_Routes.useraddress.add ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/chicken`)     
                    setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }})
                } 
                setIsLoading(false);
            }
        };  


        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Add Address</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        startIcon={isLoading ? <CircularProgress color="success" size={22}/> : <AddIcon />} 
                        sx={{ 
                            backgroundColor: "#244729", 
                            fontSize: "13px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#244710" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable" >Username </InputLabel>
                                <FormControl  sx={{  width: "100%" }}> 
                                    <InputLabel className="demo-simple-select-label" >username </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        
                                        value={formData.user_id}
                                        label="username"
                                        onChange={(e) => { handleChange("user_id", e.target.value) }}
                                        sx={{width:{xs:"100%",sm:"100%",md:"80%",   lg:"58%"} ,}}
                                    >
                                        {user.map((user) => (
                                            <MenuItem key={user.value} value={user.value}>{user.label}</MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>  
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable" >Country </InputLabel>
                                <FormControl  sx={{  width: "100%" }}> 
                                    <InputLabel className="demo-simple-select-label" >country </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        
                                        value={formData.country_id}
                                        label="username"
                                        onChange={(e) => { handleChange("country_id", e.target.value) }}
                                       sx={{width:{xs:"100%",sm:"100%",md:"80%",   lg:"58%"} ,}}
                                    >
                                        {country.map((country) => (
                                            <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>  
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">City</InputLabel>
                                    <TextField 
                                        label="city" 
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="type" 
                                        value={formData.city} 
                                        onChange={(e)=>{handleChange("city",e.target.value)}}  
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Building</InputLabel>
                                    <TextField 
                                        label="building" 
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="type" 
                                        value={formData.building} 
                                        onChange={(e)=>{handleChange("building",e.target.value)}}  
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Area</InputLabel>
                                    <TextField 
                                        label="area" 
                                        type="number"
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="type" 
                                        value={formData.area} 
                                        onChange={(e)=>{handleChange("area",e.target.value)}}  
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Street</InputLabel>
                                    <TextField 
                                        label="street" 
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="type" 
                                        value={formData.street} 
                                        onChange={(e)=>{handleChange("street",e.target.value)}}  
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Zipcode</InputLabel>
                                    <TextField 
                                        label="zipcode" 
                                        variant="outlined" 
                                         sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="type" 
                                        value={formData.zipcode} 
                                        onChange={(e)=>{handleChange("zipcode",e.target.value)}}  
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3>{`${lang.name} information`}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField 
                                        label="name" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,null,0)}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Advantages</InputLabel>
                                    <TextField 
                                        label="advantages" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="origin" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.advantages.value} 
                                        onChange={(e)=>{handleChange("advantages",e.target.value,lang.id,null,1)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Description</InputLabel>
                                    <TextField 
                                        label="description" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="type" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.description.value} 
                                        onChange={(e)=>{handleChange("description",e.target.value,lang.id,null,2)}}  
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Brief</InputLabel>
                                    <TextField 
                                        label="brief" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="type" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.brief.value} 
                                        onChange={(e)=>{handleChange("brief",e.target.value,lang.id,null,3)}}  
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            </>
        )


}
export default AddAddress