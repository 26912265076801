import React, { useState, useEffect, Fragment } from "react";
import { useParams } from 'react-router-dom';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import { Switch } from '@mui/material';

const EditShipping = () => {
    const { shipid } = useParams();
    const [isloading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [langauges, setLangauges] = useState([])
    const [ship, setship] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        days: "",
        charge_price: "",
        charge_percentage: "",
    })

    useEffect(() => {

        getship();

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);

            setDataToEditorState(prev => {
                array.forEach((e) => {
                    prev[e.id] = {
                        name: { id: 0, value: "" },
                    };
                });
                return prev;
            });

        }

    }, [])

    const getship = async () => {

        const { response, message } = await Helper.Get({
            url: api_Routes.Shipping.getOne(shipid),
            hasToken: true,
            data:{detailed:1}

        })
        if (response) {
            var details_languages = {}

            console.log(response.data)
            setFormData({
                name: response.data.name,
                days: response.data.days,
                charge_price: response.data.charge_price,
                charge_percentage: response.data.charge_percentage,

            })
            response.data.detailed_translations.forEach(ele => {
                ele.forEach(dt => {
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    details_languages[dt.language_id][dt.text_type] = { value: dt.value, id: dt.id }
                })
            })
            setDataToEditorState(details_languages)

        }

    }
    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
        if (id_lang) {
            let translations = { ...dataToEditorState };
            let valueTemp = ''

            valueTemp = value

            translations[id_lang][key] = { id: id, value: valueTemp };
            setDataToEditorState(translations);
        } else {
            setFormData(prev => ({ ...prev, [key]: value }));
        }
    };
    const handleSubmit = async () => {
        let exit = false

        const requiredFields = [
            "name",
            // "company_id",
            "days",
            "charge_price",
            "charge_percentage",
        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0) {
            enqueueSnackbar(
                `Please fill in all required fields: ${missingFields.join(", ")}`,
                {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                }
            );
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = ''

        Object.keys(dataToEditorState).forEach((key, value) => {
            Object.keys(dataToEditorState[key]).forEach((subKey) => {

                if (dataToEditorState[key][subKey].value) {
                    valueTemp = dataToEditorState[key][subKey].value;
                } else {
                    exit = true
                }

                form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
            })

        })


        Object.keys(updatedFormData).forEach((key) => {
            if (key === "file")
                form_data.append("file", updatedFormData.file);
            else
                form_data.append(key, updatedFormData[key]);
        });

        if (exit) {
            enqueueSnackbar("Please add name  ", {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            setIsLoading(false)
            return;
        }

        const { response, message } = await Helper.Post({
            url: api_Routes.Shipping.update(shipid),
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };
    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Edit Shipping Option</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isloading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField
                                        label="name"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => { handleChange("name", e.target.value) }}
                                    />
                                </Grid>

                                {/* <Grid item xs={12} sm={4}>
                                    <Typography mb="10px">Company</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>Company</InputLabel>
                                        <Select

                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}
                                            labelId="role-select-label"
                                            value={formData.company_id}
                                            label="company-id"
                                            onChange={(e) => { handleChange("company_id", e.target.value) }}
                                        >
                                            {ship.map((ID) => (
                                                <MenuItem key={ID.value} value={ID.value}>{ID.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Days</InputLabel>
                                    <TextField
                                        type="number"
                                        label="days"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="origin"
                                        value={formData.days}
                                        onChange={(e) => { handleChange("days", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Charge percentage</InputLabel>
                                    <TextField
                                        type="number"
                                        label="charge percentage"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="origin"
                                        value={formData.charge_percentage}
                                        onChange={(e) => { handleChange("charge_percentage", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Charge price</InputLabel>
                                    <TextField
                                        type="number"
                                        label="charge price"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="origin"
                                        value={formData.charge_price}
                                        onChange={(e) => { handleChange("charge_price", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (<Container sx={{ marginBottom: "20px" }}>
                    <Card>
                        <CardContent>
                            <h3>{`${lang.name} information`}</h3>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Name</InputLabel>
                                        <TextField
                                            label="name"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.name.value}
                                            onChange={(e) => { handleChange("name", e.target.value, lang.id, null, 0) }}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>))}



        </>

    )
}
export default EditShipping;