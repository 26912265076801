import React , {useState , useEffect , Fragment} from "react";
import { useParams } from 'react-router-dom';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Switch } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import dateFormat from "dateformat";
import {  Select, MenuItem } from '@mui/material';
import Files from 'react-files';


const EditEgg = () => {
    const { eggid } = useParams();
    const [isloading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [chicken, setchicken] = useState([])
    const [formData, setFormData]  = useState({
        chicken_id: "",
        price:"",
        date_laid:"",
        color:"",
        availability_for_delivery_or_store_pickup: 0,
    })

    useEffect(()=>{
       
        get_chicken()
        get_egg()

       },[])

       const get_chicken = async()=>{
        const {response, message} = await Helper.Get({
            url:api_Routes.chicken.view,
            hasToken:true,
            data:{results:100000}
        })
        if(response){
          console.log("ana");
        setchicken([])
            response.data.forEach(ele=>{
            setchicken(prev => [...prev, {
                label:ele.name, 
                value: ele.id   
            }])
            })
        }else{
            console.log(message);
        }
        }

    const get_egg = async ()=>{
            
        const {response,message} = await Helper.Get({
            url:api_Routes.egg.getOne(eggid),
            hasToken:true,
        })
        if(response){
           
            console.log(response.data)   
            setFormData({
                price:response.data.price,  
                // date_laid:dateFormat(response.data.date_laid,"dddd, mmmm dS, yyyy"),            
                date_laid:response.data.date_laid ,
                color:response.data.color,              
                // availability_for_delivery_or_store_pickup:response.data.availability_for_delivery_or_store_pickup,              
                chicken_id:response.data.chicken_id,          
                photo:response.data.photos,       
                            
            })
            setFiles(response.data.photos)

        }
    }

    console.log(formData.date_laid)

    const handleChange = (key , value) => {
        setFormData(prev => ({ ...prev, [key]: value }));
    };

    const handleChanged = (key, value) => {
        const formattedDate = value ? new Date(value)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ') 
        : null;  
        setFormData(prev => ({ ...prev, [key]: formattedDate }));
       }

      const handleSubmit = async () => {

       const requiredFields = [
    "chicken_id",
    "price",
    "date_laid",
    "color",
];

const missingFields = requiredFields.filter((field) => !formData[field]);

if (missingFields.length > 0 || !formData.photo) {
    let errorMessage = "";

    if (missingFields.length > 0) {
        errorMessage += `Please fill in all required fields: ${missingFields.join(", ")}. `;
    }

    if (!formData.photo) {
        errorMessage += `Please upload a photo.`;
    }

    enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    });

    setIsLoading(false);
    return;
}

setIsLoading(true);

        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = ''
      

        
        Object.keys(updatedFormData).forEach((key) => {
            if (key === "photo") {
                updatedFormData[key].forEach((file) => {
                    // Check if the file is a binary file (File or Blob object)
                    if (file instanceof File || file instanceof Blob) {
                        form_data.append('photos[]', file);
                    }
                    // If it's a URL (string), skip it
                });
            }  
            else
            form_data.append(key, updatedFormData[key]);
    });
    
        const { response, message } = await Helper.Post({
            url: api_Routes.egg.update(eggid),
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }})
                setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
        };

        const [files, setFiles] = useState([]);
        
        function deleteFile(indexToRemove) {
            const newFiles = files.filter((_, index) => index !== indexToRemove);
            setFiles(newFiles);
            setFormData(prev => ({
                ...prev,
                photo: newFiles.map(f => f.file) // Update formData with remaining files
            }));
        }
        const onFilesChange = (uploadedFiles) => {
            const newFiles = uploadedFiles.map(file => ({
                url: URL.createObjectURL(file),
                file // Store the actual file object for uploading later
            }));
        
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
            setFormData(prev => ({ ...prev, photo: [...prev.photo, ...newFiles.map(f => f.file)] }));
        };
        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }
        
        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Edit Egg</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        startIcon={isloading ? <CircularProgress color="success" size={22}/> : <AddIcon />} 
                        sx={{ 
                            backgroundColor: "#244729", 
                            fontSize: "13px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#244710" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3 style={{fontWeight:500,marginBottom:"30px"}}>Basic Information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Price</InputLabel>
                                    <TextField 
                                        label="price" 
                                        type="number"
                                        variant="outlined" 
                                          sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="name" 
                                        value={formData.price} 
                                        onChange={(e)=>{handleChange("price",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable" >Color</InputLabel>
                                    <TextField 
                                        label="color" 
                                        variant="outlined" 
                                          sx={{width:{xs:"100%",sm:"auto"}}}
                                        name="name" 
                                        value={formData.color} 
                                        onChange={(e)=>{handleChange("color",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable" >Date</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['DatePicker']} >
                                            <DatePicker
                                                selected={formData.date_laid}
                                                
                                                onChange={(date) => handleChanged("date_laid", date)}
                                                sx={{ overFlow:'hidden' , WebkitFlexDirection: 'row', display: 'contents' , borderRadius : '5px' , border: ' 1px solid  #e5e7eb' }}

                                               />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable" >Chicken </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        value={formData.chicken_id}
                                        label="chicken"
                                        onChange={(e) => { handleChange("chicken_id", e.target.value) }}
                                       sx={{width:{xs:"100%",sm:"90%",md:"90%",   lg:"58%"} ,}}
                                    >
                                        {chicken.map((chicken) => (
                                            <MenuItem key={chicken.value} value={chicken.value}>{chicken.label}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={true}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    <Grid container spacing={2}>
                                        {files.length > 0 && (
                                            <>
                                                {files.map((file, index) => (
                                                    <Grid item xs={12} sm={6} key={index}>
                                                        <img
                                                            width="100%" // Adjust width to fit within the grid item
                                                            height="200px"
                                                            alt="img"
                                                            src={file.url}  // Display using the URL
                                                            style={{ objectFit: "cover", borderRadius: "8px" }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                    <Button 
                                        sx={{
                                            marginTop: "20px",
                                            backgroundColor: "#244729",
                                            color: "white",
                                            padding: "10px",
                                            '&:hover': { backgroundColor: "#244710", color: "white" }
                                        }} 
                                    >
                                        Upload Image
                                    </Button>
                                </Files>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", flexDirection: "row" }}>
                                    {files.length > 0 && files.map((file, index) => (
                                        <Button
                                            key={index}
                                            onClick={() => deleteFile(index)}
                                            sx={{
                                                backgroundColor: "red",
                                                marginLeft: "8px",
                                                color: "white",
                                                padding: "8px 16px",
                                                '&:hover': { backgroundColor: "darkred", color: "white" }
                                            }}
                                        >
                                            Delete {`${index + 1}`}
                                        </Button>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>

            </>
        )
}
export default EditEgg