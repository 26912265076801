import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { api_Routes } from '../../api_Route';
import { Helper } from "../../Tools/Helper";

const ApexChart = () => {
    const [series, setSeries] = useState([{ name: 'Users', data: [] }]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.chart.user,
            hasToken: true,
            data: {}
        });

        if (response) {
            const data = response.data;
            setCategories(Object.keys(data));
            setSeries([{ name: 'Users', data: Object.values(data) }]);
            setIsLoading(false);
        } else {
            console.error(message);
            setError(message);
            setIsLoading(false);
        }
    };

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '20%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories, // Months on the X-axis
            title: {
                text: 'Months'
            }
        },
        yaxis: {
            title: {
                text: 'Number of Users'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' users';
                }
            }
        }
    };

    return (
        <Box m={2}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        yearly User Overview
                    </Typography>
                    {isLoading ? (
                        <Typography>Loading...</Typography>
                    ) : error ? (
                        <Typography>Error: {error}</Typography>
                    ) : (
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="bar"
                            height={350}
                        />
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}

export default ApexChart;
