import React, { useState, useEffect, Fragment } from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { Switch } from '@mui/material';

const AddCountry = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [breed, setbreed] = useState([])
    const [formData, setFormData] = useState({
        name: "",
        code: "",
        phone_code: "",
        is_active: 0,
    })
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);

            setDataToEditorState(prev => {
                array.forEach((e) => {
                    prev[e.id] = {
                        name: { id: 0, value: "" },
                    };
                });
                return prev;
            });
        }
    }, []);

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
        if (id_lang) {
            let translations = { ...dataToEditorState };
            let valueTemp = ''

            valueTemp = value

            translations[id_lang][key] = { id: id, value: valueTemp };
            setDataToEditorState(translations);
        } else {
            setFormData(prev => ({ ...prev, [key]: value }));
        }
    };

    const handleSubmit = async () => {
        let exit = false

        const requiredFields = [
            "name",
            "code",
            "phone_code",
        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0) {
            enqueueSnackbar(
                `Please fill in all required fields: ${missingFields.join(", ")}`,
                {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                }
            );
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = ''

        Object.keys(dataToEditorState).forEach((key, value) => {
            Object.keys(dataToEditorState[key]).forEach((subKey) => {

                if (dataToEditorState[key][subKey].value) {
                    valueTemp = dataToEditorState[key][subKey].value;
                } else {
                    exit = true
                }
                form_data.append(`languages[${key}][${subKey}]`, valueTemp);
            })

        })


        Object.keys(updatedFormData).forEach((key) => {
            if (key === "file")
                form_data.append("file", updatedFormData.file);
            else
                form_data.append(key, updatedFormData[key]);
        });


        form_data.append("_method", "PUT");

        if (exit) {
            enqueueSnackbar("Please add name  ", {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            setIsLoading(false)
            return;
        }

        const { response, message } = await Helper.Post({
            url: api_Routes.country.add,
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            navigate(`/country`)
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };


    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Add Country</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isLoading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField
                                        label="name"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => { handleChange("name", e.target.value) }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Code</InputLabel>
                                    <TextField
                                        label="code"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="origin"
                                        value={formData.code}
                                        onChange={(e) => { handleChange("code", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Phone Code</InputLabel>
                                    <TextField
                                        type="number"
                                        label="phone code"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="type"
                                        value={formData.phone_code}
                                        onChange={(e) => { handleChange("phone_code", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className="inputlable" >Is Active</InputLabel>
                                    <Switch sx={{ color: "#D80621" }} checked={formData.is_active == "1"} onChange={(e) => { handleChange("is_active", e.target.checked ? 1 : 0) }} />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (<Container sx={{ marginBottom: "20px" }}>
                    <Card>
                        <CardContent>
                            <h3>{`${lang.name} information`}</h3>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Name</InputLabel>
                                        <TextField
                                            label="name"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.name.value}
                                            onChange={(e) => { handleChange("name", e.target.value, lang.id, null, 0) }}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>))}
        </>
    )


}
export default AddCountry