import React, { useState, useEffect, Fragment } from "react";
import { Container, Box, Grid, CircularProgress, Card, CardContent, Typography, TextField, Button, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import Files from 'react-files';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import { useNavigate } from 'react-router-dom';

const AddFarm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [country, setCountry] = useState([]);
    const [breed, setBreed] = useState([]);
    const [user, setuser] = useState([])
    const [langauges, setLangauges] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        capacity: "",
        number_of_chickens: "",
        breed_ids: [],
        country_id: "",
        city: "",
        street: "",
        building: "",
        zipcode: "",
        area: "",
        longitude: "",
        latitude: "",
        user_id: [],
    });
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {
        getCountry();
        getBreed();
        getUser();
        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            setDataToEditorState(prev => {
                array.forEach((e) => {
                    prev[e.id] = {
                        name: { id: 0, value: "" },
                        description: { id: 0, value: "" },
                    };
                });
                return prev;
            });
        }
    }, []);

    const getBreed = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.breed.view,
            hasToken: true,
            data: {
                results: 190,
            }
        });
        if (response) {
            setBreed([]);
            response.data.forEach(ele => {
                setBreed(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }]);
            });
        } else {
            console.log(message);
        }
    };

    const getCountry = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.country.view,
            hasToken: true,
            data: {
                results: 190,
            }
        });
        if (response) {
            setCountry([]);
            response.data.forEach(ele => {
                setCountry(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }]);
            });
        } else {
            console.log(message);
        }
    };
    const getUser = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.user.view,
            hasToken: true,
            data: {
                results: 190,
            }
        });
        if (response) {
            setuser([]);
            response.data.forEach(ele => {
                setuser(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }]);
            });
        } else {
            console.log(message);
        }
    }

    const handleChange = (key, value, id_lang, id, sort) => {

        if (id_lang) {
            let translations = { ...dataToEditorState };
            let valueTemp = value;
            translations[id_lang][key] = { id: id, value: valueTemp };
            setDataToEditorState(translations);
        } else {
            setFormData(prev => ({ ...prev, [key]: value }));
        }
    };

    const handleSubmit = async () => {

        let exit = false;

        const requiredFields = [
            "name",
            "capacity",
            "number_of_chickens",
            "breed_ids",
            "country_id",
            "city",
            "street",
            "building",
            "zipcode",
            "area",
            "longitude",
            "latitude",
            "user_id",
        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        // if (missingFields.length > 0 || !formData.photo) {
        //     let errorMessage = "";

        //     if (missingFields.length > 0) {
        //         errorMessage += `Please fill in all required fields: ${missingFields.join(", ")}. `;
        //     }

        //     if (!formData.photo) {
        //         errorMessage += `Please upload a photo.`;
        //     }

        //     enqueueSnackbar(errorMessage, {
        //         variant: "error",
        //         anchorOrigin: {
        //             vertical: "top",
        //             horizontal: "right",
        //         },
        //     });

        //     setIsLoading(false);
        //     return;
        // }

        setIsLoading(true);

        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = '';


        Object.keys(dataToEditorState).forEach((key, value) => {
            Object.keys(dataToEditorState[key]).forEach((subKey) => {
                if (dataToEditorState[key][subKey].value) {
                    valueTemp = dataToEditorState[key][subKey].value;
                } else {
                    exit = true;
                }
                form_data.append(`languages[${key}][${subKey}]`, valueTemp);
            });
        });

        Object.keys(updatedFormData).forEach((key) => {
            if (key === "photo")
                updatedFormData[key].forEach((file, index) => {
                    form_data.append(`photos[]`, file);
                });
            else if (key === "breed_ids") {
                updatedFormData[key].forEach((breed_id) => {
                    form_data.append("breed_ids[]", breed_id);
                });
            }
            // else if (key === "user_id") {
            //     updatedFormData[key].forEach((user_id) => {
            //         form_data.append("user_id[]", user_id);
            //     });
            // }
            else {
                form_data.append(key, updatedFormData[key]);
            }
        });

        form_data.append("_method", "PUT");

        if (exit) {

            enqueueSnackbar("Please add name, advantages, description, brief", {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
            return;
        }

        const { response, message } = await Helper.Post({
            url: api_Routes.farm.add,
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
            navigate('/farms')
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };

    const [files, setFiles] = useState([]);

    const onFilesChange = (uploadedFiles) => {
        const newFiles = uploadedFiles.map(file => ({
            url: URL.createObjectURL(file),
            file // Store the actual file object for uploading later
        }));

        setFiles(prevFiles => [...prevFiles, ...newFiles]);

        setFormData(prev => ({
            ...prev,
            photo: [...(prev.photo || []), ...newFiles.map(f => f.file)] // Ensure photo is an array
        }));
    };

    function deleteFile(indexToRemove) {
        const newFiles = files.filter((_, index) => index !== indexToRemove);
        setFiles(newFiles);
        setFormData(prev => ({
            ...prev,
            photo: newFiles.map(f => f.file) // Update formData with remaining files
        }));
    }

    const onFilesError = (error, file) => {
        setFormData(prev => ({ ...prev, ["photo"]: "" }));
        setFiles(file);
    };

    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Add Farm</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isLoading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3 style={{ fontWeight: 500, marginBottom: "30px" }}>Basic Information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Farm Name</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="farm name"
                                        variant="outlined"
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => { handleChange("name", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Capacity</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        type="number"
                                        label="capacity"
                                        variant="outlined"
                                        name="capacity"
                                        value={formData.capacity}
                                        onChange={(e) => { handleChange("capacity", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Number of Chickens</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="number of chickens"
                                        variant="outlined"
                                        type="number"
                                        name="number_of_chickens"
                                        value={formData.number_of_chickens}
                                        onChange={(e) => { handleChange("number_of_chickens", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography mb="10px">Breed</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>breed</InputLabel>
                                        <Select
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}
                                            multiple
                                            labelId="role-select-label"
                                            value={formData.breed_ids}
                                            label="breed"
                                            onChange={(e) => { handleChange("breed_ids", e.target.value) }}
                                        >
                                            {breed.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography mb="10px">user</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>users</InputLabel>
                                        <Select
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}

                                            labelId="role-select-label"
                                            value={formData.user_id}
                                            label="breed"
                                            onChange={(e) => { handleChange("user_id", e.target.value) }}
                                        >
                                            {user.map((user) => (
                                                <MenuItem key={user.value} value={user.value}>{user.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={{ marginTop: "25px" }}>
                    <CardContent>
                        <h3 style={{ fontWeight: 500, marginBottom: "30px" }}>Address Information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>

                                    <Typography mb="10px">Country</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>country</InputLabel>
                                        <Select
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}
                                            labelId="role-select-label"
                                            value={formData.country_id}
                                            label="country"
                                            onChange={(e) => { handleChange("country_id", e.target.value) }}
                                        >
                                            {country.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">City</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="city"
                                        variant="outlined"
                                        name="city"
                                        value={formData.city}
                                        onChange={(e) => { handleChange("city", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Street</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="street"
                                        variant="outlined"
                                        name="street"
                                        value={formData.street}
                                        onChange={(e) => { handleChange("street", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Building</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="building"
                                        variant="outlined"
                                        name="building"
                                        value={formData.building}
                                        onChange={(e) => { handleChange("building", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Zipcode</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="zipcode"
                                        variant="outlined"
                                        name="zipcode"
                                        value={formData.zipcode}
                                        onChange={(e) => { handleChange("zipcode", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Area</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="area"
                                        variant="outlined"
                                        type="number"
                                        name="area"
                                        value={formData.area}
                                        onChange={(e) => { handleChange("area", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={{ marginTop: "25px" }}>
                    <CardContent>
                        <h3 style={{ fontWeight: 500, marginBottom: "30px" }}>Location Information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Longitude</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="longitude"
                                        type="number"
                                        variant="outlined"
                                        name="longitude"
                                        value={formData.longitude}
                                        onChange={(e) => { handleChange("longitude", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Latitude</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="latitude"
                                        type="number"
                                        variant="outlined"
                                        name="latitude"
                                        value={formData.latitude}
                                        onChange={(e) => { handleChange("latitude", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (
                <Container sx={{ marginBottom: "20px" }} key={i}>
                    <Card>
                        <CardContent>
                            <h3>{`${lang.name} information`}</h3>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Name</InputLabel>
                                        <TextField
                                            label="name"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.name.value}
                                            onChange={(e) => { handleChange("name", e.target.value, lang.id, null, 0) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Description</InputLabel>
                                        <TextField
                                            label="description"
                                            variant="outlined"
                                            fullWidth
                                            name="type"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.description.value}
                                            onChange={(e) => { handleChange("description", e.target.value, lang.id, null, 2) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            ))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} textAlign={"center"}>
                                    <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={true}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        <Grid container spacing={2}>
                                            {files.length > 0 && (
                                                <>
                                                    {files.map((file, index) => (
                                                        <Grid item xs={12} sm={6} key={index}>
                                                            <img
                                                                width="100%" // Adjust width to fit within the grid item
                                                                height="200px"
                                                                alt="img"
                                                                src={file.url}  // Display using the URL
                                                                style={{ objectFit: "cover", borderRadius: "8px" }}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </>
                                            )}
                                        </Grid>
                                        <Button
                                            sx={{
                                                marginTop: "20px",
                                                backgroundColor: "#244729",
                                                color: "white",
                                                padding: "10px",
                                                '&:hover': { backgroundColor: "#244710", color: "white" }
                                            }}
                                        >
                                            Upload Image
                                        </Button>
                                    </Files>
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", flexDirection: "row" }}>
                                        {files.length > 0 && files.map((file, index) => (
                                            <Button
                                                key={index}
                                                onClick={() => deleteFile(index)}
                                                sx={{
                                                    backgroundColor: "red",
                                                    marginLeft: "8px",
                                                    color: "white",
                                                    padding: "8px 16px",
                                                    '&:hover': { backgroundColor: "darkred", color: "white" }
                                                }}
                                            >
                                                Delete {`${index + 1}`}
                                            </Button>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default AddFarm;
