import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, Typography } from '@mui/material';

const BreedsCharts = () => {
    // Series data
    const series = [14, 23, 21, 17, 15, 10, 12, 17, 21];

    // Chart options
    const options = {
        chart: {
            type: 'polarArea',
        },
        stroke: {
            colors: ['#fff']
        },
        fill: {
            opacity: 0.8
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <Box m={2} >
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Breeds
                    </Typography>
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="polarArea"
                        height={350}
                    />
                </CardContent>
            </Card>
        </Box>
    );
}

export default BreedsCharts;
