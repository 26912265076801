import React from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import { Switch } from '@mui/material';
import { useNavigate } from "react-router-dom";
import UserCharts from "../UsersCharts/UserCharts"
import StatsCard from "../StatsCard/StatsCard"
import OrderCharts from "../OrdersChart/OrderPie"
import BreedsCharts from "../BreedsCharts/BreedsCharts"
import RentChickensCharts from "../RentChickensCharts/RentChickensCharts"
import { WidthFull } from "@mui/icons-material";
const Dashboard = () => {

  return (
    <Container>
      <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
        <Grid item xs={12} md={12} lg={12}>
          <StatsCard />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <UserCharts />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <OrderCharts />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <BreedsCharts />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RentChickensCharts />
        </Grid>
      </Grid>
    </Container >

  )
}
export default Dashboard