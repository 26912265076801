import React, { useState, useEffect, Fragment } from "react";
import { Container, Box, Grid, CircularProgress, Card, CardContent, Typography, TextField, Button, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import { useNavigate } from 'react-router-dom';

const AddCompany = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        phone_code: "",
        countries: [],
        username: "",
        first_name: "",
        second_name: "",
        password: "",
        password_confirmation: "",
    });
    const [countriesList, setCountriesList] = useState([]);
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [langauges, setLangauges] = useState([]);

    useEffect(() => {
        fetchCountries();

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);

            setDataToEditorState(prev => {
                array.forEach((e) => {
                    prev[e.id] = {
                        name: { id: 0, value: "" },
                    };
                });
                return prev;
            });
        }
    }, []);

    const fetchCountries = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.country.view,
            hasToken: true,
        });
        if (response) {
            setCountriesList(response.data.map(country => ({
                label: country.name,
                value: country.id
            })));
        } else {
            console.error(message);
        }
    };

    const handleChange = (key, value, id_lang, id, sort) => {
        if (id_lang) {
            let translations = { ...dataToEditorState };
            translations[id_lang][key] = { id: id, value };
            setDataToEditorState(translations);
        } else {
            setFormData(prev => ({ ...prev, [key]: value }));
        }
    };

    const handleSubmit = async () => {
        let exit = false;

        const requiredFields = [
            "name",
            "username",
            "first_name",
            "second_name",
            "phone",
            "phone_code",
            "password",
            "password_confirmation",
        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0 || formData.countries.length === 0) {
            const missingFieldsMessage = missingFields.length > 0 ? `Please fill in all required fields: ${missingFields.join(", ")}` : '';
            const countriesMessage = formData.countries.length === 0 ? 'Please select at least one country.' : '';

            enqueueSnackbar(
                `${missingFieldsMessage} ${countriesMessage}`,
                { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } }
            );
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        var form_data = new FormData();

        // Append non-country fields to form_data
        Object.keys(formData).forEach((key) => {
            if (key !== "countries") {
                form_data.append(key, formData[key]);
            }
        });

        // Append countries to form_data
        formData.countries.forEach((country) => {
            form_data.append("countries[]", country);
        });

        form_data.append("_method", "PUT");

        // Send data to the API
        const { response, message } = await Helper.Post({
            url: api_Routes.Company.add,
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            navigate(`/ShippingCompany`);
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };

    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Add Company</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isLoading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField
                                        label="name"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => handleChange("name", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Phone</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="phone"
                                        type="number"
                                        variant="outlined"
                                        value={formData.phone}
                                        onChange={(e) => handleChange("phone", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Phone Code</InputLabel>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label">Phone code</InputLabel>
                                        <Select
                                            sx={{ width: { xs: "100%", sm: "90%", md: "90%", lg: "58%" } }}
                                            labelId="role-select-label"
                                            value={formData.phone_code}
                                            label="Enter your phone code"
                                            onChange={(e) => handleChange("phone_code", e.target.value)}
                                        >
                                            {countriesList.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel>Countries</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            multiple
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}
                                            value={formData.countries}
                                            onChange={(e) => handleChange("countries", e.target.value)}
                                            renderValue={(selected) => selected.map(id => {
                                                const country = countriesList.find(c => c.value === id);
                                                return country ? country.label : id;
                                            }).join(', ')}
                                        >
                                            {countriesList.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>
                                                    {country.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Username</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="username"
                                        variant="outlined"
                                        value={formData.username}
                                        onChange={(e) => handleChange("username", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">First Name</InputLabel>
                                    <TextField
                                        label="first name"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        value={formData.first_name}
                                        onChange={(e) => handleChange("first_name", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Second Name</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="second name"
                                        variant="outlined"
                                        value={formData.second_name}
                                        onChange={(e) => handleChange("second_name", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Password</InputLabel>
                                    <TextField
                                        label="password"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        type="password"
                                        value={formData.password}
                                        onChange={(e) => handleChange("password", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Password Confirmation</InputLabel>
                                    <TextField
                                        label="password confirmation"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        type="password"
                                        value={formData.password_confirmation}
                                        onChange={(e) => handleChange("password_confirmation", e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {/* {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (
                    <Container key={lang.id} sx={{ marginBottom: "20px" }}>
                        <Card>
                            <CardContent>
                                <h3>{`${lang.name} information`}</h3>
                                <Box component="form" noValidate autoComplete="off">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel className="inputlable">Name</InputLabel>
                                            <TextField
                                                label="name"
                                                variant="outlined"
                                                fullWidth
                                                name="name"
                                                value={dataToEditorState[JSON.stringify(lang.id)]?.name.value}
                                                onChange={(e) => handleChange("name", e.target.value, lang.id, null, 0)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Container>
                ))
            } */}
        </>
    )
}

export default AddCompany;
