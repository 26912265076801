import React, { useState, useEffect, Fragment } from "react";
import { useParams } from 'react-router-dom';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem, FormControl } from '@mui/material';
import Files from 'react-files';

const EditChicken = () => {
    const { chickenid } = useParams();
    const [isloading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [langauges, setLangauges] = useState([])
    const [farm, setFarm] = useState([]);
    const [breed, setBreed] = useState([])
    const [formData, setFormData] = useState({
        name: "",
        breed_id: "",
        age: "",
        weight: "",
        color: "",
        health_status: "",
        farm_id: "",
    })

    useEffect(() => {
        get_Breed()
        get_chicken()
        getFarm()

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);

            setDataToEditorState(prev => {
                array.forEach((e) => {
                    prev[e.id] = {
                        name: { id: 0, value: "" },
                        // advantages: { id: 0, value: ""}, 
                        description: { id: 0, value: "" },
                        // brief: { id: 0, value: ""}, 
                    };
                });
                return prev;
            });

        }

    }, [])

    const get_Breed = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.breed.view,
            hasToken: true,
            data: {
                results: 190,
            }
        });
        if (response) {
            setBreed([]);
            response.data.forEach(ele => {
                setBreed(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }]);
            });
        } else {
            console.log(message);
        }
    };

    const getFarm = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.farm.view,
            hasToken: true,
            data: {
                results: 100,
            }
        });
        if (response) {
            setFarm([]);
            response.data.forEach(ele => {
                setFarm(prev => [...prev, {
                    label: ele.name,
                    value: ele.id,
                    breeds: ele.breeds,
                }]);
            });
        } else {
            console.log(message);
        }
    };

    const getBreed = (selectedFarmId) => {
        const selectedFarm = farm.find(f => f.value === selectedFarmId);
        if (selectedFarm) {
            setBreed([]);
            selectedFarm.breeds.forEach(ele => {
                setBreed(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }]);
            });
        }
    };

    const get_chicken = async () => {

        const { response, message } = await Helper.Get({
            url: api_Routes.chicken.getOne(chickenid),
            hasToken: true,
            data: { detailed: 1 }
        })
        if (response) {
            var details_languages = {}

            console.log(response.data)
            setFormData({
                name: response.data.name,
                age: response.data.age,
                weight: response.data.weight,
                color: response.data.color,
                health_status: response.data.health_status,
                farm_id: response.data.farm_id,
                breed_id: response.data.breed_id,
                photo: response.data.photos,

            })
            getBreed(response.data.farm_id);
            response.data.detailed_translations.forEach(ele => {
                ele.forEach(dt => {
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}


                    details_languages[dt.language_id][dt.text_type] = { value: dt.value, id: dt.id }
                })
            })
            setFiles(response.data.photos)
            // setFiles(response.data.photos?[{
            //     preview: {type: 'image', url: response.data.photos}
            // }]:"")
            setDataToEditorState(details_languages)

        }

    }


    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value);
        if (key === "farm_id") {
            setFormData(prev => ({ ...prev, farm_id: value, breed_id: "" }));
            getBreed(value);
        } else {
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = value;
                translations[id_lang][key] = { id: id, value: valueTemp };
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        }
    };

    const handleSubmit = async () => {
        let exit = false

        const requiredFields = [
            "name",
            "age",
            "weight",
            "color",
            "health_status",
            "farm_id",
            "breed_id"
        ];

        const missingFields = requiredFields.filter(field => !formData[field]);

        let errorMessage = "";

        if (missingFields.length > 0) {
            errorMessage = `Please fill in all required fields: ${missingFields.join(", ")}. `;
        }

        if (!formData.photo) {
            errorMessage += `Please upload a photo.`;
        }

        if (errorMessage !== "") {
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                }
            });

            setIsLoading(false);
            return;
        }

        setIsLoading(true);


        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = ''

        Object.keys(dataToEditorState).forEach((key, value) => {
            Object.keys(dataToEditorState[key]).forEach((subKey) => {

                if (dataToEditorState[key][subKey].value) {
                    valueTemp = dataToEditorState[key][subKey].value;
                } else {
                    exit = true
                }

                form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
            })

        })


        Object.keys(updatedFormData).forEach((key) => {
            if (key === "photo") {
                updatedFormData[key].forEach((file) => {
                    // Check if the file is a binary file (File or Blob object)
                    if (file instanceof File || file instanceof Blob) {
                        form_data.append('photos[]', file);
                    }
                    // If it's a URL (string), skip it
                });
            }
            else
                form_data.append(key, updatedFormData[key]);
        });

        console.log(updatedFormData)

        if (exit) {
            enqueueSnackbar("Please add name , advantages , description , brief  ", {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            setIsLoading(false)
            return;
        }

        const { response, message } = await Helper.Post({
            url: api_Routes.chicken.update(chickenid),
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };

    const [files, setFiles] = useState([]);

    console.log(files)


    function deleteFile(indexToRemove) {
        const newFiles = files.filter((_, index) => index !== indexToRemove);
        setFiles(newFiles);
        setFormData(prev => ({
            ...prev,
            photo: newFiles.map(f => f.file) // Update formData with remaining files
        }));
    }
    const onFilesChange = (uploadedFiles) => {
        const newFiles = uploadedFiles.map(file => ({
            url: URL.createObjectURL(file),
            file // Store the actual file object for uploading later
        }));

        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        setFormData(prev => ({ ...prev, photo: [...prev.photo, ...newFiles.map(f => f.file)] }));
    };
    const onFilesError = (error, file) => {
        setFormData(prev => ({ ...prev, ["photo"]: "" }))
        setFiles(file)
    }
    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Edit Chicken</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isloading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField
                                        label="name"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => { handleChange("name", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Farm</InputLabel>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label">farm</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            value={formData.farm_id}
                                            label="chicken"
                                            onChange={(e) => { handleChange("farm_id", e.target.value) }}
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}
                                        >
                                            {farm.map((farm) => (
                                                <MenuItem key={farm.value} value={farm.value}>{farm.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography mb="10px">Breed</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>breed</InputLabel>
                                        <Select
                                            labelId="role-select-label"
                                            value={formData.breed_id}
                                            label="breed"
                                            onChange={(e) => { handleChange("breed_id", e.target.value) }}
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" }, }}
                                        >
                                            {breed.map((breed) => (
                                                <MenuItem key={breed.value} value={breed.value}>{breed.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Age (Monthly)</InputLabel>
                                    <TextField
                                        type="number"
                                        label="age"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="origin"
                                        value={formData.age}
                                        onChange={(e) => { handleChange("age", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Weight (Kilograms)</InputLabel>
                                    <TextField
                                        type="number"
                                        label="weight"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="type"
                                        value={formData.weight}
                                        onChange={(e) => { handleChange("weight", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Color</InputLabel>
                                    <TextField
                                        label="color"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="type"
                                        value={formData.color}
                                        onChange={(e) => { handleChange("color", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Helath Status</InputLabel>
                                    <TextField
                                        label="health status"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="type"
                                        value={formData.health_status}
                                        onChange={(e) => { handleChange("health_status", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (<Container sx={{ marginBottom: "20px" }}>
                    <Card>
                        <CardContent>
                            <h3>{`${lang.name} information`}</h3>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Name</InputLabel>
                                        <TextField
                                            label="name"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.name.value}
                                            onChange={(e) => { handleChange("name", e.target.value, lang.id, dataToEditorState[JSON.stringify(lang.id)]?.name.id, 0) }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Advantages</InputLabel>
                                    <TextField 
                                        label="advantages" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="origin" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.advantages.value} 
                                        onChange={(e)=>{handleChange("advantages",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.advantages.id,1)}} 
                                    />
                                </Grid> */}
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Description</InputLabel>
                                        <TextField
                                            label="description"
                                            variant="outlined"
                                            fullWidth
                                            name="type"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.description.value}
                                            onChange={(e) => { handleChange("description", e.target.value, lang.id, dataToEditorState[JSON.stringify(lang.id)]?.description.id, 2) }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Brief</InputLabel>
                                    <TextField 
                                        label="brief" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="type" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.brief.value} 
                                        onChange={(e)=>{handleChange("brief",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.brief.id,3)}}  
                                    />
                                </Grid> */}
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} textAlign={"center"}>
                                    <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={true}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        <Grid container spacing={2}>
                                            {files.length > 0 && (
                                                <>
                                                    {files.map((file, index) => (
                                                        <Grid item xs={12} sm={6} key={index}>
                                                            <img
                                                                width="100%" // Adjust width to fit within the grid item
                                                                height="200px"
                                                                alt="img"
                                                                src={file.url}  // Display using the URL
                                                                style={{ objectFit: "cover", borderRadius: "8px" }}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </>
                                            )}
                                        </Grid>
                                        <Button
                                            sx={{
                                                marginTop: "20px",
                                                backgroundColor: "#244729",
                                                color: "white",
                                                padding: "10px",
                                                '&:hover': { backgroundColor: "#244710", color: "white" }
                                            }}
                                        >
                                            Upload Image
                                        </Button>
                                    </Files>
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", flexDirection: "row" }}>
                                        {files.length > 0 && files.map((file, index) => (
                                            <Button
                                                key={index}
                                                onClick={() => deleteFile(index)}
                                                sx={{
                                                    backgroundColor: "red",
                                                    marginLeft: "8px",
                                                    color: "white",
                                                    padding: "8px 16px",
                                                    '&:hover': { backgroundColor: "darkred", color: "white" }
                                                }}
                                            >
                                                Delete {`${index + 1}`}
                                            </Button>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    )

}
export default EditChicken