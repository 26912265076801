import React from "react";
import './App.css';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoute from "./Route/ProtectedRoute";
import { AuthProvider } from "./Route/AuthContext";
import Sidebar from "./Layout/SideBar/SideBar";
import Header from "./Layout/Header/Header";
import Dashboard from "./Component/Dashboard/index";
import Chicken from "./Component/Chicken/Chicken"
import AddChicken from "./Component/Chicken/addChicken";
import EditChicken from "./Component/Chicken/EditChicken";
import Egg from "./Component/Egg/Egg";
import AddEgg from "./Component/Egg/addEgg";
import EditEgg from "./Component/Egg/EditEgg";
import Farm from "./Component/Farms/Farm";
import AddFarm from "./Component/Farms/AddFarm";
import EditFarm from "./Component/Farms/EditFarm";
import Breed from "./Component/Breed/Breed";
import AddBreed from "./Component/Breed/AddBreed";
import EditBreed from "./Component/Breed/EditBreed";
import Role from "./Component/Role/Role";
import User from "./Component/User/User";
import AddUser from "./Component/User/AddUser";
import LogIn from "./Auth";
import EditUser from "./Component/User/EditUser";
import Balance from "./Component/User/Balance"
import Store from "./Component/Store/Store";
import Country from "./Component/Country/Country";
import AddCountry from "./Component/Country/AddCountry";
import EditCountry from "./Component/Country/EditCountry";
import AddRole from "./Component/Role/AddRole";
import EditRole from "./Component/Role/EditRole";
import Permission from "./Component/Role/Permission";
import UserAddress from "./Component/UserAddress/UserAddress";
import AddAddress from "./Component/UserAddress/AddAddress";
import EditAddress from "./Component/UserAddress/EditAddress";
import Reservation from "./Component/Reservations/Reservations";
import EditReservation from "./Component/Reservations/EditReservation";
import Page from "./Component/Pages/Pages";
import AddPage from "./Component/Pages/AddPage";
import Section from "./Component/Sections/Section";
import AddSection from "./Component/Sections/AddSection";
import EditPage from "./Component/Pages/EditPage";
import EditSection from "./Component/Sections/EditSection";
import Notification from "./Component/Notification/Notification";
import AddNotification from "./Component/Notification/AddNotification";
import EditNotification from "./Component/Notification/EditNotification";
import Shipping from "./Component/Shipping/Shipping"
import AddShipping from "./Component/Shipping/AddShipping"
import EditShipping from "./Component/Shipping/EditShipping"
import ShippingCompany from "./Component/ShippingCompany/ShippingCompany"
import AddCompany from "./Component/ShippingCompany/AddCompany";
import EditCompany from "./Component/ShippingCompany/EditCompany"
import Order from "./Component/Order/Order";
import OrderDetails from "./Component/Order/OrderDetails";
import Packages from "./Component/Packages/Packages"
import AddPackage from "./Component/Packages/AddPackage"
import EditPackage from "./Component/Packages/EditPackage"
import Settings from "./Component/Setting/Setting"
import { PauseCircle } from "@mui/icons-material";

function App() {
  return (
    <div className="App">
      <SnackbarProvider autoHideDuration={2000} maxSnack={1}>
        <BrowserRouter>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

function AppContent() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <>
      {!isLoginPage && <Sidebar />}
      <main className="content">
        {!isLoginPage && <Header />}
        <Routes>
          <Route path="/login" element={<LogIn />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chicken"
            element={
              <ProtectedRoute>
                <Chicken />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddChicken"
            element={
              <ProtectedRoute>
                <AddChicken />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditChicken/:chickenid"
            element={
              <ProtectedRoute>
                <EditChicken />
              </ProtectedRoute>
            }
          />
          <Route
            path="/egg"
            element={
              <ProtectedRoute>
                <Egg />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddEgg"
            element={
              <ProtectedRoute>
                <AddEgg />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditEgg/:eggid"
            element={
              <ProtectedRoute>
                <EditEgg />
              </ProtectedRoute>
            }
          />
          <Route
            path="/farms"
            element={
              <ProtectedRoute>
                <Farm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddFarm"
            element={
              <ProtectedRoute>
                <AddFarm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditFarm/:farmid"
            element={
              <ProtectedRoute>
                <EditFarm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/breed"
            element={
              <ProtectedRoute>
                <Breed />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddBreed"
            element={
              <ProtectedRoute>
                <AddBreed />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditBreed/:breedid"
            element={
              <ProtectedRoute>
                <EditBreed />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Role"
            element={
              <ProtectedRoute>
                <Role />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddRole"
            element={
              <ProtectedRoute>
                <AddRole />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditRole/:roleid"
            element={
              <ProtectedRoute>
                <EditRole />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Permission/:permissionname/:permissionid"
            element={
              <ProtectedRoute>
                <Permission />
              </ProtectedRoute>
            }
          />
          <Route
            path="/User"
            element={
              <ProtectedRoute>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddUser"
            element={
              <ProtectedRoute>
                <AddUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditUser/:userid"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/balance/:userid"
            element={
              <ProtectedRoute>
                <Balance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Shipping"
            element={
              <ProtectedRoute>
                <Shipping />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddShipping"
            element={
              <ProtectedRoute>
                <AddShipping />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditShipping/:shipid"
            element={
              <ProtectedRoute>
                <EditShipping />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Store"
            element={
              <ProtectedRoute>
                <Store />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Country"
            element={
              <ProtectedRoute>
                <Country />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddCountry"
            element={
              <ProtectedRoute>
                <AddCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditCountry/:countryid"
            element={
              <ProtectedRoute>
                <EditCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/useraddress"
            element={
              <ProtectedRoute>
                <UserAddress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddAddress"
            element={
              <ProtectedRoute>
                <AddAddress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditAddress/:addressid"
            element={
              <ProtectedRoute>
                <EditAddress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reservation"
            element={
              <ProtectedRoute>
                <Reservation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditReservation/:reservationid"
            element={
              <ProtectedRoute>
                <EditReservation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/page"
            element={
              <ProtectedRoute>
                <Page />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addPage"
            element={
              <ProtectedRoute>
                <AddPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditPage/:pageid"
            element={
              <ProtectedRoute>
                <EditPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/section"
            element={
              <ProtectedRoute>
                <Section />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addSection"
            element={
              <ProtectedRoute>
                <AddSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditSection/:sectionid"
            element={
              <ProtectedRoute>
                <EditSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddNotification"
            element={
              <ProtectedRoute>
                <AddNotification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditNotification/:notificationid"
            element={
              <ProtectedRoute>
                <EditNotification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ShippingCompany"
            element={
              <ProtectedRoute>
                <ShippingCompany />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddCompany"
            element={
              <ProtectedRoute>
                <AddCompany />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditCompany/:companyid"
            element={
              <ProtectedRoute>
                <EditCompany />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Packages"
            element={
              <ProtectedRoute>
                <Packages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AddPackage"
            element={
              <ProtectedRoute>
                <AddPackage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditPackage/:packageid"
            element={
              <ProtectedRoute>
                <EditPackage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/order"
            element={
              <ProtectedRoute>
                <Order />
              </ProtectedRoute>
            }
          />
          <Route
            path="/OrderDetails/:orderid"
            element={
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
    </>
  );
}

export default App;
