import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { api_Routes } from '../../api_Route';
import { Helper } from "../../Tools/Helper";

const OrderPie = () => {
    const [series, setSeries] = useState([0, 0, 0, 0, 0]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        getOrder();
    }, []);

    const getOrder = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.chart.order,
            hasToken: true,
            data: {}
        });

        if (response) {
            const data = response.data;
            const aggregatedData = {
                Pending: 0,
                Confirmed: 0,
                Delivered: 0,
                Rejected: 0,
                Canceled: 0,
            };

            for (const week in data) {
                aggregatedData.Pending += data[week].Pending;
                aggregatedData.Confirmed += data[week].Confirmed;
                aggregatedData.Delivered += data[week].Delivered;
                aggregatedData.Rejected += data[week].Rejected;
                aggregatedData.Canceled += data[week].Canceled;
            }

            setSeries([
                aggregatedData.Pending,
                aggregatedData.Confirmed,
                aggregatedData.Delivered,
                aggregatedData.Rejected,
                aggregatedData.Canceled
            ]);
            setIsLoading(false);
        } else {
            console.error(message);
            setError(message);
            setIsLoading(false);
        }
    };

    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Pending', 'Confirmed', 'Delivered', 'Rejected', 'Canceled'], // إضافة أسماء الفئات هنا
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <Box m={2} sx={{ width: "100%", height: "80%" }}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Orders (Monthly)
                    </Typography>
                    {isLoading ? (
                        <Typography>Loading...</Typography>
                    ) : error ? (
                        <Typography>Error: {error}</Typography>
                    ) : (
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={450}
                        />
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}

export default OrderPie;
