import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Container, Box, Grid, CircularProgress, Card, CardContent, Typography, TextField, Button, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import AddIcon from '@mui/icons-material/Add';

const EditCompany = () => {
    const { companyid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        phone_code: "",
        countries: [],

    });
    const [countriesList, setCountriesList] = useState([]);
    const [selectedCountryNames, setSelectedCountryNames] = useState([]); // Names for display
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        fetchCompany();
        fetchCountries();
        fetchLanguages();
    }, []);

    const fetchCompany = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.Company.getOne(companyid),
            hasToken: true,
        });
        if (response) {
            const countryIds = response.data.countries.map(country => country.id);
            const countryNames = response.data.countries.map(country => country.name);

            setFormData({
                name: response.data.name,
                phone: response.data.phone,
                phone_code: response.data.phone_code,
                countries: countryIds,

            });

            setSelectedCountryNames(countryNames); // Set names for display
        } else {
            console.error(message);
        }
    };

    const fetchCountries = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.country.view,
            hasToken: true,
        });
        if (response) {
            setCountriesList(response.data.map(country => ({
                label: country.name,
                value: country.id
            })));
        } else {
            console.error(message);
        }
    };

    const fetchLanguages = () => {
        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLanguages(array);
        }
    };

    const handleChange = (key, value) => {
        if (key === 'countries') {
            const selectedCountryIds = typeof value === 'string' ? value.split(',').map(id => parseInt(id, 10)) : value;
            setFormData(prev => ({
                ...prev,
                [key]: selectedCountryIds
            }));

            // Update selected country names
            const selectedNames = countriesList.filter(country => selectedCountryIds.includes(country.value)).map(country => country.label);
            setSelectedCountryNames(selectedNames);
        } else {
            setFormData(prev => ({ ...prev, [key]: value }));
        }
    };

    const handleSubmit = async () => {
        const requiredFields = [
            "name",
            "phone",
            "phone_code",
            "countries",

        ];

        const missingFields = requiredFields.filter(field => !formData[field]);
        if (missingFields.length > 0) {
            enqueueSnackbar(
                `Please fill in all required fields: ${missingFields.join(", ")}`,
                { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } }
            );
            return;
        }

        setIsLoading(true);
        const form_data = new FormData();

        Object.keys(formData).forEach(key => {
            if (key !== "countries") {
                form_data.append(key, formData[key]);
            }
        });

        formData.countries.forEach(country => {
            form_data.append("countries[]", country);
        });

        const { response, message } = await Helper.Post({
            url: api_Routes.Company.update(companyid),
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            navigate(`/ShippingCompany`);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
        setIsLoading(false);
    };

    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Edit Company</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isLoading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710"
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic Information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField
                                        label="name"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => handleChange("name", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Phone</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="phone"
                                        variant="outlined"
                                        value={formData.phone}
                                        onChange={(e) => handleChange("phone", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Phone Code</InputLabel>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label">Phone code</InputLabel>
                                        <Select
                                            sx={{ width: { xs: "100%", sm: "90%", md: "90%", lg: "58%" } }}
                                            labelId="role-select-label"
                                            value={formData.phone_code}
                                            label="Enter your phone code"
                                            onChange={(e) => handleChange("phone_code", e.target.value)}
                                        >
                                            {countriesList.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Countries</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            multiple
                                            sx={{ width: { xs: "100%", sm: "100%", md: "80%", lg: "58%" } }}
                                            value={formData.countries}
                                            onChange={(e) => handleChange("countries", e.target.value)}
                                            renderValue={(selected) => selected.map(id => {
                                                const country = countriesList.find(c => c.value === id);
                                                return country ? country.label : id;
                                            }).join(', ')}
                                        >
                                            {countriesList.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>
                                                    {country.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>

        </>
    );
};

export default EditCompany;
