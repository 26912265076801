import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import { useParams } from 'react-router-dom';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Box,
    Avatar,
    Container,
    CircularProgress,
} from "@mui/material";
import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DataTable from 'react-data-table-component';

import { Fragment } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useSnackbar } from 'notistack';

const Balance = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [values, setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})

    const [totalItems, setTotalItems] = useState(0)
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [recordIdToDelete, setRecordIdToDelete] = useState(null);
    const [isloadingsecure, setisloadingsecure] = useState(false);
    const { userid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isloadingadd, setIsloadingadd] = useState(false)
    const navigate = useNavigate();
    const [balance, setbalance] = useState([]);
    const [CurrentBalance, setCurrentBalance] = useState([]);
    const [formData, setFormData] = useState({
        amount: 0,
        description: "",

    })
    const handleChangeAddBalance = (key, value) => {

        setFormData(prev => ({ ...prev, [key]: value }));

    };

    const handleSubmit = async () => {
        setIsloadingadd(true)
        const requiredFields = [
            "amount",
            "description",

        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0) {
            let errorMessage = "";

            if (missingFields.length > 0) {
                errorMessage += `Please fill in all required fields: ${missingFields.join(", ")}. `;
            }


            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            setIsloadingadd(false);
            return;
        }

        var form_data = new FormData();
        form_data.append("_method", "POST");
        form_data.append("amount", formData.amount);
        form_data.append("description", formData.description);
        form_data.append("user_id", userid);

        const { response, message } = await Helper.Post({
            url: api_Routes.balance.add,
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            // navigate(`/user`);

            setIsloadingadd(false)
            setOpen(false)
            window.location.reload();

        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsloadingadd(false)
        }
    };





    const BaseApi = api_Routes.balance.getOne(userid)
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        getBalance(signal)

        return () => {
            controller.abort()
        }

    }, [values_Filter, page, perPage])


    const getBalance = async (signal) => {
        let url = BaseApi + '?1=1';

        if (values_Filter.amount) {
            url = url + `&amount=${values_Filter.amount}`;
        }
        setIsLoading(true)
        const { response, message } = await Helper.Get_Abort({
            url: url,
            hasToken: true,
            signal: signal,
            data: {
                results: perPage, page: page,
                keywords: values_Filter.keywords,
            }
        })
        if (response) {
            setData_Table([])
            // setTotalItems(response.meta.total)

            setCurrentBalance(response.data.balance)

            setbalance([]);
            response.data.transactions.forEach(ele => {
                setData_Table(prev => [...prev, {
                    amount: ele.amount,
                    created_at: ele.created_at,
                    move_type: ele.move_type,
                    type: ele.type?.name,
                    details: ele.details

                }]);
            });
            setCol_Table([
                {
                    name: 'Operation',
                    selector: row => row['type'],
                    sortable: true,
                    width: "auto",
                    center: true,
                },
                {
                    name: 'Details',
                    selector: row => row['details'],
                    sortable: true,
                    width: "auto",
                    center: true,
                },
                {
                    name: 'Date',
                    selector: row => row['created_at'],
                    sortable: true,
                    width: "auto",
                    center: true,
                },
                {
                    name: 'amount',
                    selector: row => (
                        <span style={{ color: row['move_type'] === 1 ? 'green' : 'red' }}>
                            {row['move_type'] === 1 ? `+${row['amount']}` : `-${row['amount']}`}
                        </span>
                    ),
                    sortable: true,
                    width: "auto",
                    center: true,
                },


            ])
            setIsLoading(false)
        } else {

            console.log(message);
        }
    };
    const handleChangePage = (value) => {
        console.log("per_pages", value);
        setPerPage(value)
    }

    const handlePage = (e) => {
        setPage(e)
    }

    const handleChange = (key, value) => {
        setValues(prev => ({ ...prev, [key]: value }));
    }

    const handleFilter = () => {
        setFilter_Values(values)
    }

    const handleOpenDialog = (id) => {
        setRecordIdToDelete(id);
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };




    return (
        <>
            <Dialog
                open={open}
                sx={{
                    '& .MuiPaper-root': {
                        position: "relative",
                        borderRadius: '16px', // Set your desired border radius here
                    },
                }}
                onClose={handleClose}
                aria-labelledby="add-balance-dialog-title"
                aria-describedby="add-balance-dialog-description">
                <Box sx={{ position: "absolute", right: "2%", top: "2%", border: "3px solid #292D32", borderRadius: "12px", cursor: "pointer", padding: "3px" }}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ padding: "30px 70px" }}>
                    <DialogTitle id="add-balance-dialog-title">
                        Add A Balance Or Deduct Balance
                    </DialogTitle>

                    <DialogContent sx={{ textAlign: "center" }}>
                        <DialogContentText id="add-balance-dialog-description" sx={{ marginBottom: "15px", fontWeight: "500" }}>
                            "Please enter the balnce and a description for adding or deducting balance."


                        </DialogContentText>

                        {/* Balance Input */}
                        <TextField
                            fullWidth
                            label="Balance"
                            variant="outlined"
                            value={formData.amount}
                            onChange={(e) => {
                                const value = e.target.value;

                                const regex = /^-?\d*\.?\d*$/;
                                if (regex.test(value)) {
                                    handleChangeAddBalance('amount', value);
                                }
                            }}
                            sx={{ marginBottom: "20px" }}
                        />
                        {/* Description Input */}
                        <TextField
                            fullWidth
                            label="Description"
                            variant="outlined"
                            value={formData.description}
                            onChange={(e) => handleChangeAddBalance('description', e.target.value)}
                            multiline
                            rows={3}
                        />
                    </DialogContent>

                    <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                justifyContent: "center",
                                gap: "10px"
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#00B207",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#00B207" },
                                        padding: { xs: "6px 16px", lg: "6px 16px" },
                                    }}
                                    onClick={handleSubmit}
                                >
                                    {isloadingadd ? <CircularProgress size={22} sx={{ color: "green" }} /> : "Add"}
                                </Button>
                            </Box>


                        </Box>
                    </DialogActions>
                </Box>
            </Dialog>



            <Container>
                <Container sx={{ marginBottom: "20px" }}>
                    <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                        <Grid item>
                            {isLoading ? (

                                <CircularProgress color="inherit" />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: "32px",
                                        fontWeight: "bold",
                                        color: "#fff",
                                        backgroundColor: "#4caf50",
                                        padding: "10px 20px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        display: "inline-block",
                                        textAlign: "center",
                                        minWidth: "200px"
                                    }}
                                >
                                    User Balance: {CurrentBalance}$
                                </Typography>
                            )}
                        </Grid>
                        <Grid item >
                            <Button variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: "#244729", fontSize: "13px", borderRadius: "7px", height: "38px", '&:hover': { backgroundColor: "#244710" } }}
                                onClick={handleOpen}  >
                                Add balances
                            </Button>
                        </Grid>
                    </Grid>

                    <Card sx={{ marginTop: "20px" }}>
                        <CardContent>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id="filled-basic"
                                            label="Search By amount"
                                            variant="standard"
                                            name="origin"
                                            color="success"
                                            size="small"
                                            onChange={(e) => { handleChange("amount", e.target.value) }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={1} sx={{ marginTop: "5px" }} >
                                        <Button variant="contained" sx={{ backgroundColor: "#244729", textAlign: "right", fontSize: "13px", borderRadius: "7px", height: "38px", '&:hover': { backgroundColor: "#244710" } }} onClick={() => { handleFilter() }}>
                                            <ManageSearchIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
                {isLoading ? <LinearProgress color="success" sx={{ marginTop: "30px" }} /> :
                    <Container fluid={true}>
                        <Grid container>
                            <Grid item lg={12}>
                                <Card>
                                    <CardContent>
                                        <Fragment>
                                            <DataTable
                                                data={data_table}
                                                columns={col_table}
                                                striped={true}
                                                center={true}
                                                progressPending={isLoading}
                                                progressComponent={<div >
                                                    <LinearProgress color="success" />
                                                </div>}
                                                pagination
                                                paginationServer
                                                paginationPerPage={perPage}
                                                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                                responsive
                                                paginationTotalRows={totalItems}
                                                paginationServerOptions={() => {}}
                                                onChangePage={(e) => { handlePage(e) }}
                                                onChangeRowsPerPage={(e) => { handleChangePage(e) }}
                                            />
                                        </Fragment>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                }
            </Container>
        </>
    );

}
export default Balance;