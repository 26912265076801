import React , {useState , useEffect , Fragment} from "react";
import DataTable from 'react-data-table-component';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import {  TextField } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {  Select, MenuItem } from '@mui/material';

const Store = () => {

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [ totalItems, setTotalItems ] = useState(0)
    const [openDialog, setOpenDialog] = useState(false);
    const [recordIdToDelete, setRecordIdToDelete] = useState(null);
    const [user, setuser] = useState([])

    const BaseApi = api_Routes.store.view

    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal

        getstore(signal)
        get_user()
        return()=>{
            controller.abort()
          }
          
       },[values_Filter,page,perPage])

       const get_user = async()=>{
        const {response, message} = await Helper.Get({
            url:api_Routes.user.view,
            hasToken:true,
        })
        if(response){
          console.log("ana");
            setuser([])
            response.data.forEach(ele=>{
            setuser(prev => [...prev, {
                label:ele.name, 
                value: ele.id   
            }])
            })
        }else{
            console.log(message);
        }
        }

       const getstore = async (signal) => {

        setIsLoading(true)

        let url = BaseApi + '?1=1';

      if (values_Filter.user_id ) {
        url = url + `&user_id=${values_Filter.user_id}`;
      }

      if (values_Filter.name ) {
        url = url + `&name=${values_Filter.name}`;
      }

        const {response, message} = await Helper.Get_Abort({
          
            url: url ,
              hasToken:true,
              signal:signal,
                data:{results:perPage,page:page,
                  keywords:values_Filter.keywords,               
                }
            })

            if(response){

                setData_Table([])
                setTotalItems(response.meta.total)
                console.log(response.data)
                  response.data.forEach(elem => {
                      setData_Table(prev=>[...prev,{
                        id:elem.id,
                        photo: <img src={`${elem.photo}`} style={{width:"25px",height:"25px"}}/>,
                        name:elem.name,
                        user_id:elem.user_id,
                        created_at:dateFormat(elem.created_at,"dddd, mmmm dS, yyyy"),
                        description:elem.description,
                        action: (
                            <div>
                              <span onClick={() => handleOpenDialog(elem.id)} >
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="delete-icon" >
                                <path d="M20.9999 6.72998C20.9799 6.72998 20.9499 6.72998 20.9199 6.72998C15.6299 6.19998 10.3499 5.99998 5.11992 6.52998L3.07992 6.72998C2.65992 6.76998 2.28992 6.46998 2.24992 6.04998C2.20992 5.62998 2.50992 5.26998 2.91992 5.22998L4.95992 5.02998C10.2799 4.48998 15.6699 4.69998 21.0699 5.22998C21.4799 5.26998 21.7799 5.63998 21.7399 6.04998C21.7099 6.43998 21.3799 6.72998 20.9999 6.72998Z" fill="#141414" fill-opacity="0.6"/>
                                <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="#141414" fill-opacity="0.6"/>
                                <path d="M15.2099 22.75H8.7899C5.2999 22.75 5.1599 20.82 5.0499 19.26L4.3999 9.19001C4.3699 8.78001 4.6899 8.42001 5.0999 8.39001C5.5199 8.37001 5.8699 8.68001 5.8999 9.09001L6.5499 19.16C6.6599 20.68 6.6999 21.25 8.7899 21.25H15.2099C17.3099 21.25 17.3499 20.68 17.4499 19.16L18.0999 9.09001C18.1299 8.68001 18.4899 8.37001 18.8999 8.39001C19.3099 8.42001 19.6299 8.77001 19.5999 9.19001L18.9499 19.26C18.8399 20.82 18.6999 22.75 15.2099 22.75Z" fill="#141414" fill-opacity="0.6"/>
                                <path d="M13.6601 17.25H10.3301C9.92008 17.25 9.58008 16.91 9.58008 16.5C9.58008 16.09 9.92008 15.75 10.3301 15.75H13.6601C14.0701 15.75 14.4101 16.09 14.4101 16.5C14.4101 16.91 14.0701 17.25 13.6601 17.25Z" fill="#141414" fill-opacity="0.6"/>
                                <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="#141414" fill-opacity="0.6"/>
                                </svg>
                              </span>
                            </div>
                          ),
                        }])});

                  setCol_Table([
                    {
                          name: 'Id',
                          selector: row => row['id'],
                          sortable: true,
                          width:"8%",
                          center: false,
                      },
                    {
                          name: 'Photo',
                          selector: row => row['photo'],
                          sortable: true,
                          width:"12%",
                          center: false,
                      },
                      {
                          name: 'Name',
                          selector: row => row['name'],
                          sortable: true,
                          width:"15%",
                          center: false,
                      },
                      {
                          name: 'User Id',
                          selector: row => row['user_id'],
                          sortable: true,
                          width:"14%",
                          center: true,
                      },
                      {
                          name: 'Created At',
                          selector: row => row['created_at'],
                          sortable: true,
                          width:"30%",
                          center: false,
                      },
                      // {
                      //     name: 'Description',
                      //     selector: row => row['description'],
                      //     sortable: true,
                      //     width:"10%",
                      //     center: false,
                      // },
                      {
                        name: 'Action',
                        selector: (row) => row.action,
                        sortable: true,
                        center: false,
                        width: '10%',
                      },
                      
                ])
                setIsLoading(false)
              }else{
                  console.log(message);
              }

    }

    console.log(data_table)
    
    const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }

    const handlePage =(e)=>{
        setPage(e)
    }

    const handleChange = (key, value) => {
        setValues(prev => ({ ...prev, [key]:value }));
     }

    const handleFilter = ()=>{
        setFilter_Values(values)
        }

        const handleOpenDialog = (id) => {
          setRecordIdToDelete(id);
          setOpenDialog(true);
        };
        
        const handleCloseDialog = () => {
          setOpenDialog(false);
        };
        
        const handleDeleteConfirmed = async () => {
          const {response, message} = await Helper.Delete({
            url:api_Routes.store.bulkDelete(recordIdToDelete),
            hasToken:true,
          })
          if(response){
            enqueueSnackbar(message,{variant:"success",anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }}) 
            getstore()
          }else{
            enqueueSnackbar(message,{variant:"error",anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }})
          }
          setOpenDialog(false);
        };
  
        return(
            <>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle sx={{color:"red"}}>Confirm Delete</DialogTitle>
              <DialogContent>
                <Typography>Are you sure you want to delete this Store?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button sx={{color:"red"}} onClick={handleDeleteConfirmed}>Yes, Delete</Button>
              </DialogActions>
          </Dialog>

            <Container sx={{marginBottom:"20px"}}>
                <Grid container sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <Grid item>
                      <Typography sx={{fontSize:"28px" , fontWeight:"600" , color:"#1e1b1b"}} >Store</Typography>
                    </Grid>
                </Grid>

                <Card sx={{marginTop:"20px"}}>
          <CardContent>
             <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                          <TextField 
                              id="filled-basic"
                              label="Search By Store Name" 
                              variant="standard" 
                              name="origin" 
                              color="success"
                              size="small"
                              onChange={(e)=>{handleChange("name",e.target.value)}} 
                          />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                   <FormControl variant="standard" sx={{  width: "135%" }}>
                    <InputLabel id="demo-simple-select-standard-label" sx={{color:"green !important"}}>Search By Username </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            color="success"
                            label="Search By Username"
                            onChange={(e) => { handleChange("user_id", e.target.value) }}
                            sx={{ width: "66%" }}
                            >
                            {user.map((user) => (
                                <MenuItem key={user.value} value={user.value}>{user.label}</MenuItem>
                            ))}
                       </Select>
                   </FormControl>
                  </Grid>
                  <Grid item sm={5}>

                  </Grid>
                  <Grid item  xs={12} sm={1} sx={{marginTop:"5px"}} >
                      <Button variant="contained" sx={{backgroundColor:"#244729",textAlign:"right" ,fontSize:"13px",borderRadius:"7px",height:"38px",'&:hover': {  backgroundColor: "#244710"  }}} onClick={()=> {handleFilter()}}>
                         <ManageSearchIcon />
                      </Button>
                  </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
            </Container>
          {isLoading ? <LinearProgress color="success"sx={{marginTop:"30px"}} /> : 
             <Container fluid={true}>
                <Grid container>
                    <Grid item lg={12}>
                      <Card>
                        <CardContent>
                            <Fragment>
                                <DataTable
                                    data={data_table}
                                    columns={col_table}
                                    striped={true}
                                    center={true}
                                    progressPending={isLoading}
                                    progressComponent={<div >
                                                        <LinearProgress color="success" /> 
                                                       </div>}
                                    pagination
                                    paginationServer
                                    paginationPerPage={perPage}
                                    paginationRowsPerPageOptions={[10,20,30,50,100]}
                                    responsive
                                    paginationTotalRows={totalItems}
                                    paginationServerOptions={()=>{}}
                                    onChangePage={(e)=>{handlePage(e)}}
                                    onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                />                          
                            </Fragment>
                        </CardContent>
                    </Card>
                   </Grid>
                </Grid>
            </Container>
            }
            </>
        )
}
export default Store