import React, { useState, useEffect, Fragment } from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem, FormControl } from '@mui/material';
import Files from 'react-files';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";

const AddChicken = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [langauges, setLangauges] = useState([]);
    const [farm, setFarm] = useState([]);
    const [breed, setBreed] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        age: "",
        weight: "",
        color: "",
        health_status: "",
        price: 25,
        farm_id: "",
        breed_id: "",
        photo: [] 
    });
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [files, setFiles] = useState([]);

    useEffect(() => {
        getFarm();
        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            setDataToEditorState(prev => {
                array.forEach((e) => {
                    prev[e.id] = {
                        name: { id: 0, value: "" },
                        description: { id: 0, value: "" },
                        advantages: { id: 0, value: "" },
                        brief: { id: 0, value: "" },
                    };
                });
                return prev;
            });
        }
    }, []);

    const getFarm = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.farm.view,
            hasToken: true,
            data: {
                results: 100,
            }
        });
        if (response) {
            setFarm([]);
            response.data.forEach(ele => {
                setFarm(prev => [...prev, {
                    label: ele.name,
                    value: ele.id,
                    breeds: ele.breeds,
                }]);
            });
        } else {
            console.log(message);
        }
    };

    const getBreed = (selectedFarmId) => {
        const selectedFarm = farm.find(f => f.value === selectedFarmId);
        if (selectedFarm) {
            setBreed([]);
            selectedFarm.breeds.forEach(ele => {
                setBreed(prev => [...prev, {
                    label: ele.name,
                    value: ele.id
                }]);
            });
        }
    };

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value);
        if (key === "farm_id") {
            setFormData(prev => ({ ...prev, farm_id: value, breed_id: "" }));
            getBreed(value);
        } else {
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = value;
                translations[id_lang][key] = { id: id, value: valueTemp };
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        }
    };

    console.log(formData)

    const handleSubmit = async () => {
        let exit = false;

     const requiredFields = [
    "name",
    "age",
    "weight",
    "color",
    "health_status",
    "farm_id",
    "breed_id"
];

const missingFields = requiredFields.filter(field => !formData[field]);

let errorMessage = "";

if (missingFields.length > 0) {
    errorMessage = `Please fill in all required fields: ${missingFields.join(", ")}. `;
}

if (!formData.photo) {
    errorMessage += `Please upload a photo.`;
}

if (errorMessage !== "") {
    enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: {
            vertical: "top",
            horizontal: "right"
        }
    });

    setIsLoading(false);
    return;
}

setIsLoading(true);

        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = '';

        Object.keys(dataToEditorState).forEach((key, value) => {
            Object.keys(dataToEditorState[key]).forEach((subKey) => {
                if (dataToEditorState[key][subKey].value) {
                    valueTemp = dataToEditorState[key][subKey].value;
                } else {
                    exit = true;
                }
                form_data.append(`languages[${key}][${subKey}]`, valueTemp);
            });
        });

        Object.keys(updatedFormData).forEach((key) => {
            if (key === "photo")
                updatedFormData[key].forEach((file, index) => {
                    form_data.append(`photos[]`, file);
                });         
            else
                form_data.append(key, updatedFormData[key]);
        });

        form_data.append("_method", "PUT");

        if (exit) {
            
            enqueueSnackbar("Please add name, advantages, description, brief", { variant: "error", anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }});
            setIsLoading(false);
            return;
        }

        const { response, message } = await Helper.Post({
            url: api_Routes.chicken.add,
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, { variant: "success", anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }});
            navigate(`/chicken`);
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };

    const onFilesChange = (uploadedFiles) => {
        const newFiles = uploadedFiles.map(file => ({
            url: URL.createObjectURL(file),
            file // Store the actual file object for uploading later
        }));
    
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    
        setFormData(prev => ({
            ...prev,
            photo: [...(prev.photo || []), ...newFiles.map(f => f.file)] // Ensure photo is an array
        }));
    };
    
    function deleteFile(indexToRemove) {
        const newFiles = files.filter((_, index) => index !== indexToRemove);
        setFiles(newFiles);
        setFormData(prev => ({
            ...prev,
            photo: newFiles.map(f => f.file) // Update formData with remaining files
        }));
    }

    const onFilesError = (error, file) => {
        setFormData(prev => ({ ...prev, ["photo"]: "" }));
        setFiles(file);
    };

    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Add Chicken</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isLoading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField
                                       sx={{width:{xs:"100%",sm:"auto"}}}
                                        label="name"
                                        variant="outlined"
                                        name="name"
                                        value={formData.name}
                                        onChange={(e) => { handleChange("name", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Farm</InputLabel>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label">farm</InputLabel>
                                        <Select
                                        fullWidth
                                            labelId="demo-simple-select-label"
                                            value={formData.farm_id}
                                            label="chicken"
                                            onChange={(e) => { handleChange("farm_id", e.target.value) }}
                                           sx={{width:{xs:"100%",sm:"100%",md:"80%" ,  lg:"58%"} ,}}
                                        >
                                            {farm.map((farm) => (
                                                <MenuItem key={farm.value} value={farm.value}>{farm.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography mb="10px">Breed</Typography>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" sx={{ fontSize: "15px" }}>breed</InputLabel>
                                        <Select
                                            labelId="role-select-label"
                                            value={formData.breed_id}
                                            label="breed"
                                            onChange={(e) => { handleChange("breed_id", e.target.value) }}
                                            disabled={!formData.farm_id}
                                            sx={{width:{xs:"100%",sm:"100%",md:"80%",   lg:"58%"} ,}}
                                        >
                                            {breed.map((breed) => (
                                                <MenuItem key={breed.value} value={breed.value}>{breed.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Age (Monthly)</InputLabel>
                                    <TextField
                                    sx={{width:{xs:"100%",sm:"auto"}}}
                                        label="age"
                                        type="number"
                                        variant="outlined"

                                        name="origin"
                                        value={formData.age}
                                        onChange={(e) => { handleChange("age", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Weight (Kilograms)</InputLabel>
                                    <TextField
                                    type="number"
                                       sx={{width:{xs:"100%",sm:"auto"}}}
                                        label="weight"
                                        variant="outlined"
                                        name="type"
                                        value={formData.weight}
                                        onChange={(e) => { handleChange("weight", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Color</InputLabel>
                                    <TextField
                                       sx={{width:{xs:"100%",sm:"auto"}}}
                                        label="color"
                                        variant="outlined"
                                        name="type"
                                        value={formData.color}
                                        onChange={(e) => { handleChange("color", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Health Status</InputLabel>
                                    <TextField
                                       sx={{width:{xs:"100%",sm:"auto"}}}
                                        label="health status"
                                        variant="outlined"
                                        name="type"
                                        value={formData.health_status}
                                        onChange={(e) => { handleChange("health_status", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (
                <Container sx={{ marginBottom: "20px" }} key={i}>
                    <Card>
                        <CardContent>
                            <h3>{`${lang.name} information`}</h3>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Name</InputLabel>
                                        <TextField
                                            label="name"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.name.value}
                                            onChange={(e) => { handleChange("name", e.target.value, lang.id, null, 0) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Advantages</InputLabel>
                                        <TextField
                                            label="advantages"
                                            variant="outlined"
                                            fullWidth
                                            name="origin"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.advantages.value}
                                            onChange={(e) => { handleChange("advantages", e.target.value, lang.id, null, 1) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Description</InputLabel>
                                        <TextField
                                            label="description"
                                            variant="outlined"
                                            fullWidth
                                            name="type"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.description.value}
                                            onChange={(e) => { handleChange("description", e.target.value, lang.id, null, 2) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel className="inputlable">Brief</InputLabel>
                                        <TextField
                                            label="brief"
                                            variant="outlined"
                                            fullWidth
                                            name="type"
                                            value={dataToEditorState[JSON.stringify(lang.id)]?.brief.value}
                                            onChange={(e) => { handleChange("brief", e.target.value, lang.id, null, 3) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            ))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={true}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    <Grid container spacing={2}>
                                        {files.length > 0 && (
                                            <>
                                                {files.map((file, index) => (
                                                    <Grid item xs={12} sm={6} key={index}>
                                                        <img
                                                            width="100%" // Adjust width to fit within the grid item
                                                            height="200px"
                                                            alt="img"
                                                            src={file.url}  // Display using the URL
                                                            style={{ objectFit: "cover", borderRadius: "8px" }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                    <Button 
                                        sx={{
                                            marginTop: "20px",
                                            backgroundColor: "#244729",
                                            color: "white",
                                            padding: "10px",
                                            '&:hover': { backgroundColor: "#244710", color: "white" }
                                        }} 
                                    >
                                        Upload Image
                                    </Button>
                                </Files>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", flexDirection: "row" }}>
                                    {files.length > 0 && files.map((file, index) => (
                                        <Button
                                            key={index}
                                            onClick={() => deleteFile(index)}
                                            sx={{
                                                backgroundColor: "red",
                                                marginLeft: "8px",
                                                color: "white",
                                                padding: "8px 16px",
                                                '&:hover': { backgroundColor: "darkred", color: "white" }
                                            }}
                                        >
                                            Delete {`${index + 1}`}
                                        </Button>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default AddChicken;
