import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, Typography } from '@mui/material';

const ApexLineChart = () => {
    // Series data
    const series = [{
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }];

    // Chart options
    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on rows
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    return (
        <Box m={2}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Product Trends by Month
                    </Typography>
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                    />
                </CardContent>
            </Card>
        </Box>
    );
}

export default ApexLineChart;
