import React, { useState, useEffect, Fragment } from "react";
import DataTable from 'react-data-table-component';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { TextField } from "@mui/material";
import { Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const Order = () => {

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [data_table, setData_Table] = useState([])
  const [col_table, setCol_Table] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [values, setValues] = useState({})
  const [values_Filter, setFilter_Values] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [status, setstatus] = useState([]);
  const [type, settype] = useState([]);

  const BaseApi = api_Routes.order.view

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    get_order(signal)
    get_status()
    get_type()

    return () => {
      controller.abort()
    }

  }, [values_Filter, page, perPage])

  const get_status = () => {
    const response = {
      data: [
        { name: "Pending", id: 1 },
        { name: "Delivery", id: 2 },
        { name: "Delivered", id: 3 },
        { name: "Canceled", id: 5 },
        { name: "Rejected", id: 4 },
      ]
    };
    setstatus([]);
    response.data.forEach(ele => {

      setstatus(prev => [...prev, {
        label: ele.name,
        value: ele.id
      }]);

    });
  }

  const get_type = () => {
    const response = {
      data: [
        { name: "Chicken", id: 1 },
        { name: "Egg", id: 2 },

      ]
    };
    settype([]);
    response.data.forEach(ele => {

      settype(prev => [...prev, {
        label: ele.name,
        value: ele.id
      }]);

    });
  }

  const get_order = async (signal) => {

    let url = BaseApi + '?1=1';

    if (values_Filter.name) {
      url = url + `&name=${values_Filter.name}`;
    }
    if (values_Filter.status) {
      url = url + `&status=${values_Filter.status}`;
    }
    if (values_Filter.type) {
      url = url + `&type=${values_Filter.type}`;
    }

    setIsLoading(true)

    const { response, message } = await Helper.Get_Abort({

      url: url,
      hasToken: true,
      signal: signal,
      data: {
        results: perPage, page: page,
        keywords: values_Filter.keywords,
      }
    })

    if (response) {

      setData_Table([])
      setTotalItems(response.meta.total)

      response.data.forEach(elem => {
        setData_Table(prev => [...prev, {
          id: elem.id,
          status: elem.status === 1 ? "Pending" : elem.status === 2 ? "Delivery" : elem.status === 3 ? "Delivered" : elem.status === 4 ? "Rejected" : "Done",
          total: elem.total,
          type: elem.type === 1 ? "Chickens" : "Eggs",
          quantity: elem.quantity,
          photo: <img src={elem.photo} alt="orderphoto" style={{ height: "40px", width: "40px", borderRadius: "50%" }} />,
          action: (
            <div>
              <span style={{ paddingLeft: "13px" }} onClick={() => { navigate(`/OrderDetails/${elem.id}`) }}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="edit-icon">
                  <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#141414" fill-opacity="0.6" />
                  <path d="M8.50008 17.69C7.89008 17.69 7.33008 17.47 6.92008 17.07C6.43008 16.58 6.22008 15.87 6.33008 15.12L6.76008 12.11C6.84008 11.53 7.22008 10.78 7.63008 10.37L15.5101 2.49C17.5001 0.499998 19.5201 0.499998 21.5101 2.49C22.6001 3.58 23.0901 4.69 22.9901 5.8C22.9001 6.7 22.4201 7.58 21.5101 8.48L13.6301 16.36C13.2201 16.77 12.4701 17.15 11.8901 17.23L8.88008 17.66C8.75008 17.69 8.62008 17.69 8.50008 17.69ZM16.5701 3.55L8.69008 11.43C8.50008 11.62 8.28008 12.06 8.24008 12.32L7.81008 15.33C7.77008 15.62 7.83008 15.86 7.98008 16.01C8.13008 16.16 8.37008 16.22 8.66008 16.18L11.6701 15.75C11.9301 15.71 12.3801 15.49 12.5601 15.3L20.4401 7.42C21.0901 6.77 21.4301 6.19 21.4801 5.65C21.5401 5 21.2001 4.31 20.4401 3.54C18.8401 1.94 17.7401 2.39 16.5701 3.55Z" fill="#141414" fill-opacity="0.6" />
                  <path d="M19.8501 9.83003C19.7801 9.83003 19.7101 9.82003 19.6501 9.80003C17.0201 9.06003 14.9301 6.97003 14.1901 4.34003C14.0801 3.94003 14.3101 3.53003 14.7101 3.41003C15.1101 3.30003 15.5201 3.53003 15.6301 3.93003C16.2301 6.06003 17.9201 7.75003 20.0501 8.35003C20.4501 8.46003 20.6801 8.88003 20.5701 9.28003C20.4801 9.62003 20.1801 9.83003 19.8501 9.83003Z" fill="#141414" fill-opacity="0.6" />
                </svg>
              </span>
            </div>
          ),
        }])
      });

      setCol_Table([{
        name: 'Id',
        selector: row => row['id'],
        sortable: true,
        width: "10%",
        center: false,
      },
      {
        name: 'Photo',
        selector: row => row['photo'],
        sortable: true,
        width: "12%",
        center: false,
      },
      {
        name: 'Quantity',
        selector: row => row['quantity'],
        sortable: true,
        width: "16%",
        center: true,
      },
      {
        name: 'Type',
        selector: row => row['type'],
        sortable: true,
        width: "16%",
        center: false,
      },
      {
        name: 'Status',
        selector: row => row['status'],
        sortable: true,
        width: "16%",
        center: false,
      },
      {
        name: 'Total',
        selector: row => row['total'],
        sortable: true,
        width: "16%",
        center: false,
      },
      {
        name: 'Action',
        selector: (row) => row.action,
        sortable: true,
        center: false,
        width: '13%',
      },

      ])
      setIsLoading(false)
    } else {
      console.log(message);
    }

  }

  const handleChangePage = (value) => {
    console.log("per_pages", value);
    setPerPage(value)
  }

  const handlePage = (e) => {
    setPage(e)
  }

  const handleChange = (key, value) => {
    setValues(prev => ({ ...prev, [key]: value }));
  }

  const handleFilter = () => {
    setFilter_Values(values)
  }

  return (<>

    <Container sx={{ marginBottom: "20px" }}>
      <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid item>
          <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }} >Order</Typography>
        </Grid>
      </Grid>

      <Card sx={{ marginTop: "20px" }}>
        <CardContent>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="filled-basic"
                  label="Search By Name"
                  variant="standard"
                  name="origin"
                  color="success"
                  size="small"
                  onChange={(e) => { handleChange("name", e.target.value) }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="standard" sx={{ width: "135%" }}>
                  <InputLabel id="demo-simple-select-standard-label" sx={{ color: "green !important" }}>Search By Status </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    color="success"
                    label="Search By Status"
                    onChange={(e) => { handleChange("status", e.target.value) }}
                    sx={{ width: "66%" }}
                  >
                    {status.map((chicken) => (
                      <MenuItem key={chicken.value} value={chicken.value}>{chicken.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="standard" sx={{ width: "135%" }}>
                  <InputLabel id="demo-simple-select-standard-label" sx={{ color: "green !important" }}>Search By Type </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    color="success"
                    label="Search By Type"
                    onChange={(e) => { handleChange("type", e.target.value) }}
                    sx={{ width: "66%" }}
                  >
                    {type.map((chicken) => (
                      <MenuItem key={chicken.value} value={chicken.value}>{chicken.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} >

              </Grid>
              <Grid item xs={12} sm={1} sx={{ marginTop: "5px" }} >
                <Button variant="contained" sx={{ backgroundColor: "#244729", textAlign: "right", fontSize: "13px", borderRadius: "7px", height: "38px", '&:hover': { backgroundColor: "#244710" } }} onClick={() => { handleFilter() }}>
                  <ManageSearchIcon />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>

    {isLoading ? <LinearProgress color="success" sx={{ marginTop: "30px" }} /> :
      <Container fluid={true}>
        <Grid container>
          <Grid item lg={12}>
            <Card>
              <CardContent>
                <Fragment>
                  <DataTable
                    data={data_table}
                    columns={col_table}
                    striped={true}
                    center={true}
                    progressPending={isLoading}
                    progressComponent={<div >
                      <LinearProgress color="success" />
                    </div>}
                    pagination
                    paginationServer
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                    responsive
                    paginationTotalRows={totalItems}
                    paginationServerOptions={() => {}}
                    onChangePage={(e) => { handlePage(e) }}
                    onChangeRowsPerPage={(e) => { handleChangePage(e) }}
                  />
                </Fragment>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    }


  </>)

}
export default Order;