import React, { useState, useEffect, Fragment } from "react";
import DataTable from 'react-data-table-component';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Switch } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import Files from 'react-files';

const AddUser = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [country, setcountry] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [formData, setFormData] = useState({
        username: "",
        first_name: "",
        second_name: "",
        phone: "",
        phone_code: "",
        password: "",
        password_confirmation: "",
        role_id: "",
        verified: "",
        active: 0,
        email: "",
        facebook_account: "",
        instagram_account: "",
        tiktok_account: "",
        twitter_account: "",
    })

    useEffect(() => {

        get_country()

    }, []);
    const get_country = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.country.view,
            hasToken: true,
            data: {
                results: 190,
            },
        });
        if (response) {

            setcountry([]);
            response.data.forEach((ele) => {
                setcountry((prev) => [
                    ...prev,
                    {
                        id: ele.id,
                        label: ele.name,
                        value: ele.phone_code,
                    },
                ]);
            });
        } else {
            console.log(message);
        }
    };

    const handleChange = (key, value) => {

        setFormData(prev => ({ ...prev, [key]: value }));

    };

    const handleSubmit = async () => {

        const requiredFields = [
            "username",
            "first_name",
            "second_name",
            "phone",
            "phone_code",
            "password",
            "password_confirmation",
            "role_id",
        ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0 || !formData.photo) {
            let errorMessage = "";

            if (missingFields.length > 0) {
                errorMessage += `Please fill in all required fields: ${missingFields.join(", ")}. `;
            }

            if (!formData.photo) {
                errorMessage += `Please upload a photo.`;
            }

            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = ''




        Object.keys(updatedFormData).forEach((key) => {
            if (key === "photo")
                form_data.append("photo", updatedFormData.photo);
            else
                form_data.append(key, updatedFormData[key]);
        });


        form_data.append("_method", "PUT");



        const { response, message } = await Helper.Post({
            url: api_Routes.user.add,
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            navigate(`/user`);
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setIsLoading(false);
        }
    };

    const [files, setFiles] = useState([]);

    function deleteFile(e) {
        setFiles([]);
        setFormData(prev => ({ ...prev, ["photo"]: "" }))
        return files

    }
    const onFilesChange = (files) => {
        setFiles(files)
        setFormData(prev => ({ ...prev, ["photo"]: files[0] }))
        console.log(files);
    }
    const onFilesError = (error, file) => {
        setFormData(prev => ({ ...prev, ["photo"]: "" }))
        setFiles(file)
    }


    return (
        <>
            <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Add User</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={isLoading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                            sx={{
                                backgroundColor: "#244729",
                                fontSize: "13px",
                                borderRadius: "7px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: "#244710" // Green color on hover
                                }
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Username</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="username"
                                        variant="outlined"

                                        value={formData.username}
                                        onChange={(e) => { handleChange("username", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">First Name</InputLabel>
                                    <TextField
                                        label="first name"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}

                                        value={formData.first_name}
                                        onChange={(e) => { handleChange("first_name", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Second Name</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="second name"
                                        variant="outlined"

                                        value={formData.second_name}
                                        onChange={(e) => { handleChange("second_name", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Role </InputLabel>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel className="demo-simple-select-label" >role </InputLabel>
                                        <Select
                                            labelId="role-select-label"
                                            value={formData.role_id}
                                            label="Role"
                                            onChange={(e) => { handleChange("role_id", e.target.value) }}
                                            sx={{ width: { xs: "100%", sm: "90%", md: "90%", lg: "58%" }, }}
                                        >
                                            <MenuItem value="4">User</MenuItem>
                                            <MenuItem value="1">Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>

                                    <Typography mb="10px"> Phone Code</Typography>
                                    <FormControl
                                        sx={{ width: "100%" }}

                                    >
                                        <InputLabel
                                            sx={{ fontSize: "13px" }}
                                            InputLabelProps={{
                                                style: { color: "#434343", fontSize: "13px" },
                                            }}
                                        >
                                            phone code
                                        </InputLabel>
                                        <Select
                                            fullWidth

                                            label="Enter your phone code"
                                            InputLabelProps={{
                                                style: { color: "#434343", fontSize: "13px" },
                                            }}
                                            sx={{ width: { xs: "100%", sm: "90%", md: "90%", lg: "58%" }, }}
                                            onChange={(e) => {
                                                const [label, value] = e.target.value.split("|");
                                                handleChange("phone_code", value);  // Store the phone code
                                                handleChange("selectedCountry", label);  // Optional: Store the country label if needed
                                            }}
                                            renderValue={(selected) => {
                                                if (!selected) return "";
                                                const [label, value] = selected.split("|");
                                                return `${label} ${value}`;
                                            }}
                                        >
                                            {country.map((country) => (
                                                <MenuItem
                                                    key={country.id}
                                                    value={`${country.label}|${country.label === "Canada" || country.label === "United States" ? "+1" : country.value}`}
                                                >
                                                    {country.label} {country.value}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>


                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Phone</InputLabel>
                                    <TextField
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        label="phone"
                                        variant="outlined"

                                        value={formData.phone}
                                        onChange={(e) => { handleChange("phone", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Password</InputLabel>
                                    <TextField
                                        label="password"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        type="password"
                                        value={formData.password}
                                        onChange={(e) => { handleChange("password", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Password Confirmation</InputLabel>
                                    <TextField
                                        label="password confirmation"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        type="password"
                                        value={formData.password_confirmation}
                                        onChange={(e) => { handleChange("password_confirmation", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Email </InputLabel>
                                    <TextField
                                        label="email"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        value={formData.email}
                                        onChange={(e) => { handleChange("email", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable" >Active</InputLabel>
                                    <Switch sx={{ color: "#D80621" }} checked={formData.active == "1"} onChange={(e) => { handleChange("active", e.target.checked ? 1 : 0) }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable" >Verified</InputLabel>
                                    <Switch sx={{ color: "#D80621" }} checked={formData.verified == "1"} onChange={(e) => { handleChange("verified", e.target.checked ? 1 : 0) }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="inputlable">Facebook Account</InputLabel>
                                    <TextField
                                        label="facebook account"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        value={formData.facebook_account}
                                        onChange={(e) => { handleChange("facebook_account", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="inputlable">Instagram Account</InputLabel>
                                    <TextField
                                        label="instagram account"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        value={formData.instagram_account}
                                        onChange={(e) => { handleChange("instagram_account", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="inputlable">Tiktok Account</InputLabel>
                                    <TextField
                                        label="tiktok account"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        value={formData.tiktok_account}
                                        onChange={(e) => { handleChange("tiktok_account", e.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="inputlable">Twitter Account </InputLabel>
                                    <TextField
                                        label="twitter account"
                                        variant="outlined"
                                        sx={{ width: { xs: "100%", sm: "auto" } }}
                                        value={formData.twitter_account}
                                        onChange={(e) => { handleChange("twitter_account", e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={false}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        {
                                            files.length > 0
                                                ? <div style={{ textAlign: "center" }}>
                                                    {files.map((file, index) =>
                                                        <div key={index}>
                                                            <img width="400px" height="200px" alt="img" src={`${file.preview.url}`} />
                                                        </div>
                                                    )}
                                                </div>

                                                : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                                                    <Button sx={{ backgroundColor: "#244729", color: "white", padding: "10px", '&:hover': { backgroundColor: "#244710", color: "white" } }} >Upload Image</Button>
                                                </div>
                                        }
                                    </Files>
                                    {files.length > 0 ?
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                                            <Button onClick={() => deleteFile(files)} sx={{ backgroundColor: "red", color: "white", padding: "8px 16px", '&:hover': { backgroundColor: "red", color: "white" } }}    >
                                                Delete
                                            </Button>
                                        </div> : ''
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    )


}
export default AddUser