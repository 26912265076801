import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Button, TextField, Typography, CircularProgress, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import AddIcon from '@mui/icons-material/Add';


const Settings = () => {
    // const [settings, setSettings] = useState({});
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState([])
    const [isloadingfordata, setisLoadingfordata] = useState(true);

    useEffect(() => {



        get_Setting();

    }, []);

    const get_Setting = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.Settings.view,
            hasToken: true,
        });

        if (response) {
            console.log(response.data);
            setFormData(response.data);
            setisLoadingfordata(false);
        } else {
            setisLoadingfordata(false);
            console.log(message);
        }
    };
    const handleChange = (id, value) => {
        const newData = formData.map(item => {
            if (item.id === id) {
                return { ...item, value: value };

            }
            return item;

        });
        setFormData(newData);
    }

    const formatData = () => {
        const formattedData = {};
        formData.forEach(item => {
            formattedData[item.id.toString()] = item.value;
        });
        return formattedData;
    }
    const handleSubmit = async () => {
        const formattedData = formatData();
        setIsLoading(true);
        const { response, message } = await Helper.Post({
            url: api_Routes.Settings.update,
            data: formattedData,
            hasToken: true
        })

        if (response) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            });
            setIsLoading(false);
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            });
            setIsLoading(false);
        }
    };





    return (
        <Container className='mb-3'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <Grid container>
                            {/* Header and Save Button */}
                            <Grid item lg={10} xl={10} md={10} sm={10} xs={10}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Settings
                                    </Typography>
                                </CardContent>
                            </Grid>

                            <Grid
                                item lg={2} xl={2} md={2} sm={2} xs={2}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Button
                                    variant="contained"
                                    startIcon={isLoading ? <CircularProgress color="success" size={22} /> : <AddIcon />}
                                    sx={{
                                        backgroundColor: "#244729",
                                        fontSize: "13px",
                                        borderRadius: "7px",
                                        height: "38px",
                                        '&:hover': {
                                            backgroundColor: "#244710" // Green color on hover
                                        }
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                        <CardContent>
                            {isloadingfordata ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', }}>
                                    <CircularProgress sx={{ color: "green" }} />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={3}>
                                        {/* Form Fields */}
                                        {formData.map(({ id, key, value }) => (
                                            <Grid item xs={12} key={id}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={6} lg={4} md={4}>
                                                        <Typography>{key}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} lg={4} md={4}>
                                                        <TextField
                                                            fullWidth
                                                            value={value}
                                                            onChange={(e) => handleChange(id, e.target.value)}
                                                            variant="outlined"
                                                            label={key}
                                                            required
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );




};
export default Settings