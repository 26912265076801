import React , {useState , useEffect , Fragment} from "react";
import { useParams } from 'react-router-dom';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import {  Select, MenuItem } from '@mui/material';
import Files from 'react-files';

const EditBreed = () => {
    const { breedid } = useParams();
    const [isloading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [langauges, setLangauges] = useState([])
    const [farm, setfarm] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        origin:"",
        type:"",
    })

    useEffect(()=>{
       
        get_breed()

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            
                setDataToEditorState(prev => {
                    array.forEach((e) => {
                        prev[e.id] = {
                            name: { id: 0, value: "" },
                            advantages: { id: 0, value: ""}, 
                            disadvantages: { id: 0, value: ""}, 
                            additional_notes: { id: 0, value: ""}, 
                        };
                    });
                    return prev;
                });
            
        }

       },[])

    const get_breed = async ()=>{
            
        const {response,message} = await Helper.Get({
            url:api_Routes.breed.getOne(breedid),
            hasToken:true,
            data:{detailed:1}

        })
        if(response){
            var details_languages = {}

            console.log(response.data)   
            setFormData({
                name:response.data.name,  
                origin:response.data.origin,              
                type:response.data.type,              
                // farm_id:response.data.farm_id,    
                photo:response.data.photos,    

                        
            })
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            setFiles(response.data.photos)

            setDataToEditorState(details_languages)

        }

    }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

      const handleSubmit = async () => {
        let exit = false

        if (!formData.name || !formData.origin || !formData.type  ) {
            enqueueSnackbar("Please add name , origin , type ",{variant:"error",anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }})
    setIsLoading(false);
    return;
}
        setIsLoading(true);

        var form_data = new FormData();
        var updatedFormData = { ...formData };
        var valueTemp = ''
      
        Object.keys(dataToEditorState).forEach((key,value) => {
            Object.keys(dataToEditorState[key]).forEach((subKey) => {
               
                        if (dataToEditorState[key][subKey].value) {
                            valueTemp = dataToEditorState[key][subKey].value;
                        } else {
                             exit = true
                        }
                    
                    form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
            })

        })

        
        Object.keys(updatedFormData).forEach((key) => {
            if (key === "photo") {
                updatedFormData[key].forEach((file) => {
                    // Check if the file is a binary file (File or Blob object)
                    if (file instanceof File || file instanceof Blob) {
                        form_data.append('photos[]', file);
                    }
                    // If it's a URL (string), skip it
                });
            }
            else
            form_data.append(key, updatedFormData[key]);
    });

        if(exit){
            enqueueSnackbar("You should add the name , advanteges , disadvatages , additional notes ",{variant:"error",anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }})
            setIsLoading(false)
            return;
        }
    
        const { response, message } = await Helper.Post({
            url: api_Routes.breed.update(breedid),
            data: form_data,
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }})
                setIsLoading(false);
        } else {
            if (typeof message === "string") {
                enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})
            } 
            setIsLoading(false);
        }
        };

        const [files, setFiles] = useState([]);
        
        function deleteFile(indexToRemove) {
            const newFiles = files.filter((_, index) => index !== indexToRemove);
            setFiles(newFiles);
            setFormData(prev => ({
                ...prev,
                photo: newFiles.map(f => f.file) // Update formData with remaining files
            }));
        }
        const onFilesChange = (uploadedFiles) => {
            const newFiles = uploadedFiles.map(file => ({
                url: URL.createObjectURL(file),
                file // Store the actual file object for uploading later
            }));
        
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
            setFormData(prev => ({ ...prev, photo: [...prev.photo, ...newFiles.map(f => f.file)] }));
        };        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }
        
        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Edit Breed</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        startIcon={isloading ? <CircularProgress color="success" size={22}/> : <AddIcon />} 
                        sx={{ 
                            backgroundColor: "#244729", 
                            fontSize: "13px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#244710" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3 style={{fontWeight:500,marginBottom:"30px"}}>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField 
                                        label="name" 
                                        variant="outlined" 
                                         
                                        name="name" 
                                        value={formData.name} 
                                        onChange={(e)=>{handleChange("name",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Origin</InputLabel>

                                    <TextField 
                                        label="origin" 
                                        variant="outlined" 
                                         
                                        name="origin" 
                                        value={formData.origin} 
                                        onChange={(e)=>{handleChange("origin",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Type</InputLabel>

                                    <TextField 
                                        label="type" 
                                        variant="outlined" 
                                         
                                        name="type" 
                                        value={formData.type} 
                                        onChange={(e)=>{handleChange("type",e.target.value)}}  
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3 style={{fontWeight:500,marginBottom:"30px"}}>{`${lang.name} information`}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Name</InputLabel>

                                    <TextField 
                                        label="name" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.name.id,0)}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Advantages</InputLabel>

                                    <TextField 
                                        label="advantages" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="origin" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.advantages.value} 
                                        onChange={(e)=>{handleChange("advantages",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.advantages.id,1)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Disadvantages</InputLabel>

                                    <TextField 
                                        label="disadvantages" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="type" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.disadvantages.value} 
                                        onChange={(e)=>{handleChange("disadvantages",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.name.disadvantages,2)}}  
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Additional Notes</InputLabel>

                                    <TextField 
                                        label="additional notes" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="type" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.additional_notes.value} 
                                        onChange={(e)=>{handleChange("additional_notes",e.target.value,lang.id,dataToEditorState[JSON.stringify(lang.id)]?.additional_notes.id,3)}}  
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={true}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    <Grid container spacing={2}>
                                        {files.length > 0 && (
                                            <>
                                                {files.map((file, index) => (
                                                    <Grid item xs={12} sm={6} key={index}>
                                                        <img
                                                            width="100%" // Adjust width to fit within the grid item
                                                            height="200px"
                                                            alt="img"
                                                            src={file.url}  // Display using the URL
                                                            style={{ objectFit: "cover", borderRadius: "8px" }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                    <Button 
                                        sx={{
                                            marginTop: "20px",
                                            backgroundColor: "#244729",
                                            color: "white",
                                            padding: "10px",
                                            '&:hover': { backgroundColor: "#244710", color: "white" }
                                        }} 
                                    >
                                        Upload Image
                                    </Button>
                                </Files>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", flexDirection: "row" }}>
                                    {files.length > 0 && files.map((file, index) => (
                                        <Button
                                            key={index}
                                            onClick={() => deleteFile(index)}
                                            sx={{
                                                backgroundColor: "red",
                                                marginLeft: "8px",
                                                color: "white",
                                                padding: "8px 16px",
                                                '&:hover': { backgroundColor: "darkred", color: "white" }
                                            }}
                                        >
                                            Delete {`${index + 1}`}
                                        </Button>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            </>
        )

}
export default EditBreed