import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';
import { Helper } from "../../Tools/Helper";
import { api_Routes } from "../../api_Route";

const OrderDetails = () => {
    const { orderid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState({});

    useEffect(() => {
        getOrder();
    }, []);

    const getOrder = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.order.getOne(orderid),
            hasToken: true
        });
        if (response) {
            setIsLoading(false);
            setOrder(response.data);
        } else {
            setIsLoading(false);
            console.log(message);
        }
    };

    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Grid container spacing={3}>
                    {/* User Information Section */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <img
                                            src={order.user_photo || "https://via.placeholder.com/50"}
                                            alt="User Profile"
                                            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6">{order.user_name || "User Name"}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {order.created_at || "June 18-21, 2020"}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Phone: {order.user_phone || "(+963) 987 654 321"}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            E-mail: {order.user_email || "username@gmail.com"}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Address: {order.user_address_country_name + order.user_address_city + order.user_address_area + order.user_address_street + order.user_address_building || "4517 Washington Ave. Manchester, Kentucky 39495 (603) 555-0123"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Order Summary Section */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Order Summary</Typography>
                                <Box mt={2}>
                                    <Grid container>
                                        {/* <Grid item xs={6}>
                                            <Typography variant="body2">Subtotal:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">${order.subtotal || "1856.2"}</Typography>
                                        </Grid> */}
                                        <Grid item xs={6}>
                                            <Typography variant="body2">Delivery:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">${order.delivery || "20"}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">Quantity:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{order.quantity || "10"}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h6">Total Order:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" color="primary">
                                                ${order.total || "1894.32"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Product Details Section */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Product Details</Typography>
                                <Box mt={2}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={2}>
                                            <img
                                                src={order.photo || "https://via.placeholder.com/50"}
                                                alt="Product"
                                                style={{ width: "100%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2">Chicken Name:</Typography>
                                            <Typography variant="body2">{order.product?.name || "Chicken name"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default OrderDetails;
