import React , {useState , useEffect , Fragment} from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography , TextField  } from "@mui/material";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import Files from 'react-files';
import {  Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useParams } from 'react-router-dom';

const EditSection = () => {
    const { sectionid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [page2, setpage2] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        url:"",
        page_id:""
    })
    const [dataToEditorState, setDataToEditorState] = useState({});


    useEffect(() => {

        get_page()
        get_section()
        

        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            
                setDataToEditorState(prev => {
                    array.forEach((e) => {
                        prev[e.id] = {
                            name: { id: 0, value: "" },
                            description: { id: 0, value: "" },
                        };
                    });
                    return prev;
                });    
        }
    }, []);

    const get_page = async()=>{
        const {response, message} = await Helper.Get({
            url:api_Routes.page.view,
            hasToken:true,
        })
        if(response){
            setpage2([])
            response.data.forEach(ele=>{
            setpage2(prev => [...prev, {
                label:ele.name, 
                value: ele.id   
            }])
            })
        }else{
            console.log(message);
        }
        }

        const get_section = async ()=>{
            
            const {response,message} = await Helper.Get({
                url:api_Routes.section.getOne(sectionid),
                hasToken:true,
                data:{detailed:1}
            })
            if(response){
                var details_languages = {}
    
                console.log(response.data.translations)   
                setFormData({
                    name:response.data.name,  
                    url:response.data.url, 
                    page_id:response.data.page_id,  
                    photo:response.data.photo,                           
                })
                response.data.detailed_translations.forEach(ele=>{
                    ele.forEach(dt=>{
                        if (!details_languages[dt.language_id])
                            {
                            details_languages[dt.language_id] = {}
                            }
                            details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                    })
                })
                setFiles(response.data.photo?[{
                    preview: {type: 'image', url: response.data.photo}
                }]:"")
                setDataToEditorState(details_languages)
    
            }
    
        }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

     const handleSubmit = async () => {
            let exit = false

                const requiredFields = [
       "name",
        "url",
         "page_id"
];

const missingFields = requiredFields.filter(field => !formData[field]);

let errorMessage = "";

if (missingFields.length > 0) {
    errorMessage = `Please fill in all required fields: ${missingFields.join(", ")}. `;
}

if (!formData.photo) {
    errorMessage += `Please upload a photo.`;
}

if (errorMessage !== "") {
    enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: {
            vertical: "top",
            horizontal: "right"
        }
    });

    setIsLoading(false);
    return;
}

setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "photo")
                    form_data.append("photo", updatedFormData.photo);
                else
                    form_data.append(key, updatedFormData[key]);
            });

              
                form_data.append("_method", "PUT");
            
            if(exit){
                enqueueSnackbar("Please add english and french name ",{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: api_Routes.section.add ,
                data: form_data,
                hasToken: true
            });

            if (response) {
                enqueueSnackbar(message,{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})       
                  navigate(`/section`)     
                    setIsLoading(false);
            } else {
                let errorMessage = '';
                if (typeof message === "string") {
                    errorMessage = message;
                } else if (typeof message === "object") {
                    errorMessage = Object.values(message).flat().join(', ');
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setIsLoading(false);
            }
        };  

        const [files, setFiles] = useState([]);
        
        function deleteFile(e) {
            setFiles([]);
            setFormData(prev=>({...prev,["photo"]:""}))
            return files
    
        }
        const onFilesChange = (files) => {
            setFiles(files)
            setFormData(prev=>({...prev,["photo"]:files[0]}))
            console.log(files);
        }
        const onFilesError = (error, file) => {
            setFormData(prev=>({...prev,["photo"]:""}))
            setFiles(file)
        }


        return(
            <>
                <Container sx={{ marginBottom: "20px" }}>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Grid item>
                        <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "#1e1b1b" }}>Edit Section</Typography>
                    </Grid>
                    <Grid item>
                    <Button 
                        variant="contained" 
                        startIcon={isLoading ? <CircularProgress color="success" size={22}/> : <AddIcon />} 
                        sx={{ 
                            backgroundColor: "#244729", 
                            fontSize: "13px", 
                            borderRadius: "7px", 
                            height: "38px",
                            '&:hover': {
                                backgroundColor: "#244710" // Green color on hover
                            }
                        }} 
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <h3>Basic information</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField 
                                        label="name" 
                                        variant="outlined" 
                                         fullWidth
                                        name="name" 
                                        value={formData.name} 
                                        onChange={(e)=>{handleChange("name",e.target.value)}} 
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable">Url</InputLabel>
                                    <TextField 
                                        label="url" 
                                        variant="outlined" 
                                        fullWidth
                                        name="origin" 
                                        value={formData.url} 
                                        onChange={(e)=>{handleChange("url",e.target.value)}} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <InputLabel className="inputlable" >Page </InputLabel>
                                    <FormControl  sx={{  width: "100%" }}> 
                                        <InputLabel className="demo-simple-select-label" >page </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            
                                            value={formData.page_id}
                                            label="Breed"
                                            onChange={(e) => { handleChange("page_id", e.target.value) }}
                                            sx={{ width: "100%" }}
                                        >
                                            {page2.map((page) => (
                                                <MenuItem key={page.value} value={page.value}>{page.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {
            Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <h3>{`${lang.name} information`}</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Name</InputLabel>
                                    <TextField 
                                        label="name" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="name" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.name.value} 
                                        onChange={(e)=>{handleChange("name",e.target.value,lang.id,null,0)}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <InputLabel className="inputlable">Description</InputLabel>
                                    <TextField 
                                        label="description" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="type" 
                                        value={dataToEditorState[JSON.stringify(lang.id)]?.description.value} 
                                        onChange={(e)=>{handleChange("description",e.target.value,lang.id,null,1)}}  
                                    />
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>))}
            <Container sx={{ marginBottom: "50px" }}>
                <Card>
                    <CardContent>
                        <h3>Upload Image</h3>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                        <Files
                                        className='files-dropzone fileContainer'
                                        onChange={onFilesChange}
                                        onError={onFilesError}
                                        accepts={['image/*']}
                                        multiple={false}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                        >
                                                {
                                                    files.length > 0
                                                        ? <div style={{textAlign:"center"}}>
                                                            {files.map((file, index) =>
                                                                <div key={index}>
                                                                    <img width="400px" height="200px"  alt= "img" src= {`${file.preview.url}`}  />
                                                                </div>
                                                            )}
                                                        </div>

                                                        : <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                            <Button sx={{backgroundColor:"#244729",color:"white",padding:"10px",'&:hover': {  backgroundColor: "#244710",color:"white"  }}} >Upload Image</Button>
                                                        </div>
                                                }
                                        </Files>
                                            {files.length > 0 ?
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                                                    <Button  onClick={() => deleteFile(files)} sx={{backgroundColor:"red",color:"white",padding:"8px 16px",'&:hover': {  backgroundColor: "red",color:"white"  }}}    >
                                                        Delete
                                                    </Button>
                                                </div> : ''
                                            }
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            </>
        )


}
export default EditSection